export default class AlgoliaVariantsTransformer {
  constructor (variants) {
    this.variants = variants
  }

  get transformedData () {
    const languageCode = I18n.acceptLanguage.split('-')[0]

    return this.variants.map((variant) => {
      return {
        id: variant.product.id,
        type: 'products',
        attributes: {
          ...variant.product,
          'brand-name': variant.product['brand-name'][languageCode],
          'closeup-image-urls': [
            variant['closeup-image-url']
          ],
          'display-price': variant['display-price'],
          name: variant.product.name[languageCode]
        }
      }
    })
  }
}
