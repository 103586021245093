const routes = {
  baseURL: '',
  basePath: '/api',
  graphQLBaseUrl: vueSupport.graphQLBaseUrl || ''
}

const headers = {
  'X-Requested-With': 'XMLHttpRequest',
  'Accept-Language': I18n.acceptLanguage,
  'X-Platform': vueSupport.isMobile ? 'mobile_web' : 'web'
}

// To rename and refactor headers
// v2m5mHeaders is the new default headers for native APIs
const v2m5mHeaders = {
  'X-Site-Country': I18n.siteCountryLs.toUpperCase(),
  'X-App-Platform': vueSupport.isMobile ? 'web_mobile' : 'web_desktop',
  'X-App-Version': '1.0.0',
  'Content-Type': 'application/json'
}

export {
  routes,
  headers,
  v2m5mHeaders
}
