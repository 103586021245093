import { isSsrActivated } from '@/mixins/ssrUtil'

async function checkValidUser (to, callback) {
  if (!vueSupport.currentUser) {
    const route = { query: { 'return-to': to.path } }

    const ssrActivated = await isSsrActivated()

    if (ssrActivated) {
      route.path = '/sign_in'
    } else {
      route.path = '/'
      route.hash = 'authentication'
    }

    callback(route)
    return
  }

  callback()
}

export {
  checkValidUser
}
