import splitio from '@/modules/services/split-io'

function isSsrActivated () {
  return splitio.getTreatment(
    'ssr_activated',
    { key: vueSupport.bucketingHeaders['X-SSR'] }
  )
}

function isListingPages (to, from) {
  return (['categories-show', 'brands-show'].includes(to.name)) &&
    from.path !== '/' // to prevent redirect loop when accessing categories and brands page directly
}

function isHomepage (to, from) {
  return to.path === '/' && from.path !== '/'
}

export {
  isSsrActivated,
  isListingPages,
  isHomepage
}
