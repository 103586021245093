const test = {
  facebook: {
    au: {
      appId: 'facebookAuAppId'
    },
    bd: {
      appId: 'facebookBdAppId'
    },
    bn: {
      appId: 'facebookBnAppId'
    },
    hk: {
      appId: 'facebookHkAppId'
    },
    id: {
      appId: 'facebookIdAppId'
    },
    jp: {
      appId: 'facebookJpAppId'
    },
    kr: {
      appId: 'facebookKrAppId'
    },
    la: {
      appId: 'facebookLaAppId'
    },
    lk: {
      appId: 'facebookLkAppId'
    },
    mm: {
      appId: 'facebookMmAppId'
    },
    my: {
      appId: 'facebookMyAppId'
    },
    nz: {
      appId: 'facebookNzAppId'
    },
    ph: {
      appId: 'facebookPhAppId'
    },
    pk: {
      appId: 'facebookPkAppId'
    },
    sg: {
      appId: 'facebookSgAppId'
    },
    th: {
      appId: 'facebookThAppId'
    },
    tw: {
      appId: 'facebookTwAppId'
    },
    vn: {
      appId: 'facebookVnAppId'
    }
  }
}
export default test
