const DEEPLINK_PREFIX = 'sephora://'

function deeplinkPath (path, slug) {
  let finalPath = `${DEEPLINK_PREFIX}${path}`
  if (slug !== null && slug !== undefined && slug !== '') {
    finalPath += `/${slug}`
  }

  return finalPath
}

function deeplinkForRoute (route) {
  let path
  let slug

  if (route.name === 'home-index') {
    path = 'home'
  } else if (route.name.includes('store-location')) {
    path = 'store-locations'
  } else if (route.name === 'products-show' || route.name === 'products-show-variant') {
    path = 'product'
    slug = route.params.id
  } else if (route.name === 'brands-show') {
    path = 'brand'
    slug = route.params.id
  } else if (route.name === 'categories-show') {
    path = 'category'
    slug = Array.isArray(route.params.id) ? route.params.id.join('/') : route.params.id
  } else if (route.name === 'landing-pages-show') {
    path = 'landing-page'
    slug = route.params.id
  } else if (route.name === 'landing-pages-sale') {
    path = 'landing-page'
    slug = route.meta.entityId
  } else if (route.name === 'product-groups-show') {
    path = 'product-group'
    slug = route.params.id
  }

  return deeplinkPath(path, slug)
}

export {
  deeplinkForRoute
}
