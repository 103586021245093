import ApiBase from './api-base'

class Brands extends ApiBase {
  constructor () {
    super()

    this.url = '/v2.5/brands'
  }

  async fetchBrands (params = {}) {
    const { data } = await this.client.get(this.url, {
      params: {
        'page[size]': params.pageSize || 500,
        'page[number]': params.pageNumber || 1
      }
    })
    return data
  }
}

export default Brands
