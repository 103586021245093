import { OktaAuth } from '@okta/okta-auth-js'
import secrets from '@/modules/config/secrets'

const { issuer, clientId } = secrets.oktaWidgetConfig
const { redirectUri, scopes, useInteractionCodeFlow, pkce } = {
  redirectUri: `${window.location.origin}/login/callback`,
  scopes: ['openid', 'profile', 'email'],
  useInteractionCodeFlow: false,
  useClassicEngine: false,
  pkce: true
}

const authClient = new OktaAuth({
  url: issuer.split('/oauth2')[0],
  clientId: clientId,
  redirectUri: redirectUri,
  issuer: issuer,
  scopes: scopes,
  useInteractionCodeFlow: useInteractionCodeFlow,
  responseType: 'code',
  pkce: pkce
})

export default authClient
