import { headers } from '@/modules/config/api'
import ApiBase from '@/modules/api/api-base'
import axios from 'axios'

function setupAxios () {
  window.axios = new ApiBase().client
  setAxiosHeaders(window.axios)

  window.htmlAxios = axios
  setAxiosHeaders(window.htmlAxios)
}

function setAxiosHeaders (axiosInstance) {
  axiosInstance.defaults.headers.common = {
    ...(document.getElementsByName('csrf-token').length > 0
      ? {
        'X-CSRF-TOKEN': document
          .getElementsByName('csrf-token')[0]
          .getAttribute('content')
      }
      : {}),
    ...headers
  }
}

export {
  setupAxios
}
