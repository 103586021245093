import { disassemble } from 'hangul-js'
class DefaultBrandGroupingStrategy {
  brandsStartingWithInitialChar (brands, char) {
    return brands.filter(brand => brand.attributes['name'].charAt(0).toLowerCase() === char)
  }
}

class KoBrandGroupingStrategy {
  brandsStartingWithInitialChar (brands, char) {
    const arr = ['ㄱ', 'ㄲ', 'ㄴ', 'ㄷ', 'ㄸ', 'ㄹ', 'ㅁ', 'ㅂ', 'ㅃ', 'ㅅ', 'ㅆ', 'ㅇ', 'ㅈ', 'ㅉ', 'ㅊ', 'ㅋ', 'ㅌ', 'ㅍ', 'ㅎ']
    return brands.filter((brand) => {
      const name = brand.attributes['name']
      const initialChar = disassemble(name.charAt(0))[0]

      if (arr.includes(char)) {
        // select brands which name starts with initialChar
        // ex. if char is 'ㄱ' => '가넬, '가또미르', '가슁'
        return char === initialChar
      } else if (char === '#') {
        // select brands which name doesn't start with Korean language.
        // ex. '369 가방', 'sephora'
        return !arr.includes(initialChar)
      }
    })
  }
}
export {
  DefaultBrandGroupingStrategy,
  KoBrandGroupingStrategy
}
