import ApiBase from './api-base'

class Brands extends ApiBase {
  constructor () {
    super()

    this.url = '/v2/layout_config'
  }

  async fetchLayoutConfig (params = {}) {
    const { data } = await this.client.get(this.url)
    return data
  }
}

export default Brands
