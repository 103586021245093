import Brands from '@/modules/api/brands'
import { KoBrandGroupingStrategy, DefaultBrandGroupingStrategy } from '@/modules/services/brand-grouping-strategy'

const state = {
  allBrands: [],
  productBrands: [],
  brandsLoaded: false
}

const getters = {
  brandsSortedByAlphabeticalOrder: (state) => (brands) => {
    return brands.concat().sort((a, b) => a.attributes.name.localeCompare(b.attributes.name))
  },

  brandGroupingStratgey: (state, getters) => () => {
    if (I18n.language === 'lng_ko') {
      return new KoBrandGroupingStrategy()
    } else if (I18n.language === 'lng_xz') {
      return new DefaultBrandGroupingStrategy()
    } else {
      return new DefaultBrandGroupingStrategy()
    }
  },

  findBrandsStartingWithInitalChar: (state, getters) => (char) => {
    return getters.brandGroupingStratgey().brandsStartingWithInitialChar(state.allBrands, char)
  },

  brandsStartingWithInitialChar: (state, getters) => (char) => {
    const brands = getters.findBrandsStartingWithInitalChar(char)
    return getters.brandsSortedByAlphabeticalOrder(brands)
  },

  findBrandById: (state) => (id) => {
    return state.allBrands.find(brand => brand.id === id.toString())
  },

  findBrand: (state) => (brand) => {
    if (brand.attributes) {
      return brand
    } else {
      return state.allBrands.find(b => b.id === b.id.toString())
    }
  },

  brandForProduct: (state) => (product) => {
    return state.allBrands.find(brand => brand.id === product.relationships.brand.data.id) || state.productBrands.find(brand => brand.id === product.relationships.brand.data.id)
  },

  brandsStartingWithNumber: (state, getters) => {
    const brands = state.allBrands.filter(brand => !isNaN(parseInt(brand.attributes['name'].charAt(0))))
    return getters.brandsSortedByAlphabeticalOrder(brands)
  }
}

const mutations = {
  setAllBrands (state, brands) {
    state.allBrands = brands
  },

  setProductBrands (state, array) {
    state.productBrands = array
  },

  setBrandsLoaded (state, boolean) {
    state.brandsLoaded = boolean
  }
}

const actions = {
  async fetchBrands ({ commit }) {
    const brandsApi = new Brands()

    try {
      const { data } = await brandsApi.fetchBrands()
      commit('setAllBrands', data)
      commit('setBrandsLoaded', true)
    } catch (error) {}
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
