import GraphqlBase from './graphql-base'
import getProducts from './queries/product.gql'

class ProductGraphQL extends GraphqlBase {
  async fetchProducts (entityType, entitySlug, filterBy, sortBy, pageSize, pageNumber) {
    const { data, loading, error } = await this.graphqlClient.query({
      query: getProducts,
      variables: { entityType, entitySlug, filterBy, sortBy, pageSize, pageNumber }
    })
    return { data, loading, error }
  }
}

export default ProductGraphQL
