class ServiceAgreement {
  adaptData (data) {
    return data.map(element => {
      return {
        id: element.id,
        name: `serviceAgreement${element.id}`,
        text: element.attributes['display-name'],
        validationRules: element.attributes['required'] ? 'required' : false,
        textType: 'html'
      }
    })
  }
}

export default ServiceAgreement
