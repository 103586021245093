import { pushDataLayerEvent } from '@/modules/services/gtm'

function modalShow (selector) {
  const $el = $(selector)
  $el.modal('show')

  // For some reason on some browsers, the modal z-index is screwed up if the modal is deep in the DOM
  // So we detach it and append it to the body to ensure it's on top
  $el.detach().appendTo('body')
}

const gwpModalUtil = {
  methods: {
    showGwpModal () {
      if (vueSupport.checkout.isMobile === 'true') {
        $('#gwp-mobile-modal').show()
      } else {
        modalShow('#gwp-desktop-modal')

        // The width of the carousel was getting calculated wrongly because the modal was hidden.
        // initialize the carousel once the modal is opened resulting in a carousel that has width
        setTimeout(() => {
          $('.js-samples-carousel').sepCarousel()
        }, 400)
      }
    },

    hideGwpModal () {
      if (vueSupport.checkout.isMobile === 'true') {
        $('#gwp-mobile-modal').hide()
      } else {
        $('#gwp-desktop-modal').modal('hide')
      }
    },

    showNoSelectionModal () {
      modalShow('#no-selection-confirmation-modal')
    },

    hideNoSelectionModal () {
      $('#no-selection-confirmation-modal').modal('hide')
    },

    recordClosingGwpModalGtm () {
      pushDataLayerEvent(
        'exit_gwp', {
          params: {
            event_category: 'GWP Selection Screen',
            event_action: 'Leave GWP',
            event_label: ''
          }
        }
      )
    },

    recordSubmittingGwpModalGtm () {
      pushDataLayerEvent(
        'submit_gwp', {
          params: {
            event_category: 'GWP Selection Screen',
            event_action: 'Done with GWP',
            event_label: ''
          }
        }
      )
    },

    recordSelectionChangeOnGwpModalGtm () {
      pushDataLayerEvent(
        'change_gwp_selection', {
          params: {
            event_category: 'Coupons on Cart',
            event_action: 'Change Selection',
            event_label: vueSupport.cartDiscountCouponCode
          }
        }
      )
    }
  }
}

export { gwpModalUtil }
