import ApiBase from './api-base'
import * as DataFormatter from '@/components/checkout-v3/api/data-formatter'
import * as Bugsnag from '@/modules/services/bugsnag'
import { routes } from '@/modules/config/api'

class GlobalConfig extends ApiBase {
  constructor () {
    super()

    this.url = '/internal_api/global_config'
  }

  async fetch () {
    try {
      const { data } = await this.client.get(this.url, {
        baseURL: routes.baseURL
      })
      return {
        success: true,
        data: DataFormatter.format(data)
      }
    } catch (e) {
      Bugsnag.notifyBugsnag(e)
      return { success: false, data: null, message: e.message }
    }
  }
}

export default GlobalConfig
