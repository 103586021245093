export default class SearchBrandsTransformer {
  constructor (brands, included) {
    this.brands = brands
    this.included = included
  }

  get transformedData () {
    return this.brands.map((brand) => {
      return this.included.find((included) => {
        return included.id.toString() === brand.id.toString() && included.type === 'brands'
      })
    })
  }
}
