import ApiBase from './api-base'
import { isEarlyAccessFlagOn } from './product'

class Wishlist extends ApiBase {
  constructor () {
    super()
    this.url = `/v2.3/wishlist`
    this.params = {
      skips: ['wishlist', 'waitlist'],
      include: 'variants.product'
    }
  }

  async fetchVariants () {
    const { data } = await this.client.get('/v2.5/wishlist')
    return data
  }

  async fetch ({
    sort = '',
    brandId = '',
    availability = '',
    pageNumber = 1,
    // 500 is to follow how brands is fetch
    // this is by legacy design although ideally we should paginate
    // temporary do not paginate as we would require to fetch all user's wishlisted items to mark as wishlisted on product page
    pageSize = 500
  } = {}) {
    const version = await isEarlyAccessFlagOn() ? '2.6' : '2.3'
    const { data } = await this.client.get(`/v${version}/my/wishlist/variants`, {
      params: {
        include: 'product',
        'page[number]': pageNumber,
        'page[size]': pageSize,
        ...(brandId ? { 'filter[brand]': brandId } : {}),
        ...(availability ? { 'filter[availability]': availability } : {})
      }
    })

    return data
  }

  async filters () {
    const { data } = await this.client.get('/v2.3/my/wishlist/filters')

    return data
  }

  async add (variantIds = '') {
    const url = `${this.url}/variants/${variantIds}`

    const { data } = await this.client.put(url, {}, {
      params: this.params
    })

    return data
  }

  async remove (variantIds = '') {
    const url = `${this.url}/variants/${variantIds}`

    const { data } = await this.client.delete(url, {
      params: this.params
    })

    return data
  }
}

export default Wishlist
