import Feedback from '../../modules/api/feedback'

const digitalStoreIdentifier = window.localStorage.getItem('digitalStoreIdentifier')

const state = {
    storeIdentifier: digitalStoreIdentifier
}

const mutations = {
    setStore (state, storeIdentifier) {
        state.storeIdentifier = storeIdentifier
    }
}

const actions = {
    async pushFeedbackToSheet ({ rootState }, args) {
      const user = rootState.user.currentDCUser
      const sheetData = Object.assign({ user: user ? user.card_number : null }, args.sheetData)
      const feedbackApi = new Feedback()
      const response = await feedbackApi.pushFeedbackToSheet({
        storeCode: args.storeCode,
        sheetData: sheetData
      })
      return response
    }
}

export default {
    state,
    mutations,
    actions
}
