import get from 'lodash/get'
import isNil from 'lodash/isNil'
import splitio from '@/modules/services/split-io'
import Authentication from '@/modules/api/authentication'
import ServiceAgreement from '@/modules/api/serviceAgreement'
import { notifyBugsnag } from '@/modules/services/bugsnag'
import { redirectToReturnPathIfPresent } from '@/modules/services/utilities'
import GTM, { pushDataLayerEvent } from '@/modules/services/gtm'
import { authenticationSteps, signUpTypes } from '@/modules/config/authentication'
import { getCookie, deleteCookieWithPath } from '@/modules/services/cookies'
import jwtDecode from 'jwt-decode'
import authClient from '@/modules/services/oauth/okta'

const state = {
  authErrors: [],
  authLoading: false,
  authStep: authenticationSteps.VALIDATE,
  pendingAuthUser: {}, // temporary store for user info across authentication steps,
  prefilledForRetail: false,
  securityPolicyDescriptions: '',
  showFacebookLogin: true,
  showGoogleLogin: true,
  showAppleLogin: true,
  showKakaotalkLogin: I18n.siteCountryLs === 'kr',
  showNaverLogin: I18n.siteCountryLs === 'kr',
  serviceAgreements: [],
  availableSocialProviders: ['Google', 'Facebook', 'Apple', 'Kakaotalk', 'Naver']
}

const getters = {
  pendingAuthUserEmail: state => get(state.pendingAuthUser, 'email')
}

const mutations = {
  setAuthErrors (state, array) {
    state.authErrors = array
  },

  setAuthLoading (state, boolean) {
    state.authLoading = boolean
  },

  setAuthStep (state, string) {
    state.authStep = string
  },

  setPendingAuthUser (state, object) {
    state.pendingAuthUser = object
  },

  setPrefilledForRetail (state, boolean) {
    state.prefilledForRetail = boolean
  },

  setSecurityPolicyDescriptions (state, string) {
    state.securityPolicyDescriptions = string
  },

  setShowFacebookLogin (state, bool) {
    state.showFacebookLogin = bool
  },

  setShowGoogleLogin (state, bool) {
    state.showGoogleLogin = bool
  },

  setShowAppleLogin (state, bool) {
    state.showAppleLogin = bool
  },

  setShowNaverLogin (state, bool) {
    state.showNaverLogin = bool
  },

  setShowKakaotalkLogin (state, bool) {
    state.showKakaotalkLogin = bool
  },

  setAvailableSocialProviders (state, array) {
    state.availableSocialProviders = array
  },

  setServiceAgreements (state, array) {
    state.serviceAgreements = array
  },

  setSocialParams (state, object) {
    state.socialParams = object
  }

}

const actions = {
  async checkExistingUser ({ commit, dispatch }, { requestParams, finalStep }) {
    commit('setAuthLoading', true)

    try {
      const { data } = await new Authentication().existingUser(requestParams)

      const existOnline = get(data, 'attributes["user-exists-online"]')
      const existCrm = get(data, 'attributes["user-exists-in-crm"]')
      const nextAuthStep = authStepAfterCheckExistence(existOnline, existCrm, requestParams, finalStep)
      commit('setAuthStep', nextAuthStep)
      if (nextAuthStep === authenticationSteps.OKTA_SIGN_IN) {
        dispatch('redirectToOkta', { email: requestParams.email })
      }
    } catch (error) {
      commit('setNotification', { msg: get(error, 'response.data.errors.detail'), type: 'error' })
    } finally {
      commit('setAuthLoading', false)
    }
  },

  async checkRetailMembership ({ commit }, args) {
    commit('setAuthLoading', true)

    const response = await new Authentication().existingRetailMember(args)

    if (response.success) {
      commit('setAuthErrors', [])
      commit('setAuthStep', authenticationSteps.REGISTRATION_FORM)
      commit('setPendingAuthUser', response.data)
    } else {
      commit('setAuthErrors', response.errors)
    }

    commit('setAuthLoading', false)
  },

  async signIn ({ commit, dispatch }, args) {
    const authApi = new Authentication()

    if (accountVerificationEnabled()) {
      if (state.pendingAuthUser && state.pendingAuthUser.loginMethod) {
        args.grantType = 'social_email_verification'
      }
    }

    await authApi.signIn(args)

    // action comes from user store
    await dispatch('fetchUser', null, { root: true })
  },

  async marketplaceSignIn ({ commit, dispatch }, args) {
    commit('setAuthLoading', true)

    const authApi = new Authentication()
    await authApi.marketplaceSignIn(args)
  },

  async signUp ({ commit, state, dispatch }, args) {
    commit('setAuthLoading', true)

    const user = {
      firstName: state.pendingAuthUser.firstName || args.firstName,
      lastName: state.pendingAuthUser.lastName || args.lastName,
      email: args.email,
      dob: args.dob || state.pendingAuthUser.dob,
      mobile: args.mobile,
      password: args.password,
      marketingCommunicationOptIns: args.marketingCommunicationOptIns,
      marketingEmailCommunicationOptIns: args.marketingEmailCommunicationOptIns,
      country: args.country,
      recaptchaResponse: args.recaptchaResponse,
      storeCode: args.storeCode,
      channelCode: args.channelCode,
      type: args.type,
      baId: args.baId,
      serviceAgreementsIds: args.serviceAgreementsIds,
      fortertoken: args.fortertoken
    }

    if (args.type === signUpTypes.acquisition) {
      const response = await new Authentication().createRetailAcquisition(user)
      window.location.replace(response.data.attributes.redirect_url)
    } else {
      try {
        if (args.type === signUpTypes.lazada) {
          user['linkingToken'] = args.linkingToken
          await new Authentication().createMarketplaceUser(user)
        } else if (accountVerificationEnabled()) {
          await new Authentication().createUnverifiedUser(user)
        } else {
          await new Authentication().createUser(user)

          // action comes from user store
          await dispatch('fetchUser', null, { root: true })
        }
      } finally {
        commit('setAuthLoading', false)
      }
    }
  },

  async digitalCatalogLogin ({ commit }, args) {
    const authApi = new Authentication()
    const response = await authApi.digitalCatalogLogin({
      email: args.email, dob: args.dob
    })
    commit('setCurrentDCUser')

    const cardNumber = response['included'][0]['attributes']['card-number']
    pushDataLayerEvent('sotf_user_sign_in', { card_number: cardNumber })

    return response
  },

  async signOut () {
    try {
      await new Authentication().destroySession()
    } catch (error) {
      notifyBugsnag(error)
    }
  },

  digitalCatalogSignOut ({ commit }, args) {
    commit('destroyDCUser')
    deleteCookieWithPath('digital-catalog-session')
  },

  async resetPassword ({ commit }, args) {
    try {
      const { meta, errors } = await new Authentication().resetPassword(args)
      if (!errors) {
        commit('setNotification', { msg: meta.message, type: 'error' })
      } else {
        commit('setNotification', { msg: get(errors, 'meta.message'), type: 'error' })
      }
    } catch (error) {
      notifyBugsnag(error)
    }
  },

  async verifyEmail ({ commit }, args) {
    try {
      const { meta, errors } = await new Authentication().verifyEmail(args)
      if (!errors) {
        commit('setNotification', { msg: meta.message, type: 'error' })
      } else {
        commit('setNotification', { msg: get(errors, 'meta.message'), type: 'error' })
      }
    } catch (error) {
      notifyBugsnag(error)
    }
  },

  async resendOtp ({ commit }, args) {
    try {
      await new Authentication().resendOtp(args)
    } catch (error) {
      notifyBugsnag(error)
    }
  },

  async socialLogin ({ commit, dispatch }, args) {
    const authApi = new Authentication()
    let email

    if (args.provider === 'facebook' || args.provider === 'kakao') {
      ({ email } = await args.oAuth.getUserDetails())
    } else {
      email = jwtDecode(args.token).email
    }
    const { data } = await authApi.existingUser({ email: email })
    const existOnline = get(data, 'attributes["user-exists-online"]')

    if (args.type === signUpTypes.lazada) {
      await authApi.marketplaceSocialSignIn(args)
    } else if (oktaLoginEnabled() && isSephoraEmail(email) && existOnline) {
      commit('setAuthStep', authenticationSteps.OKTA_SIGN_IN)
      dispatch('redirectToOkta', { email: email })
    } else {
      const { included } = await authApi.createSocialSession(args)

      commit('setCurrentUser', included[0])
    }
  },

  async socialRegister ({ commit }, args) {
    let response

    if (args.type === signUpTypes.lazada) {
      response = await new Authentication().registerSocialMarketplaceUser(args)
    } else {
      response = await new Authentication().registerSocialUser(args)
    }

    if (response.success) {
      commit('setAuthErrors', [])
      commit('setCurrentUser', response.data)
    } else {
      commit('setAuthErrors', response.errors)
    }
  },

  // all post authentication logic goes here
  async handlePostAuthentication ({ rootState, commit, dispatch }, { gaEvent = {}, options = {} } = {}) {
    // update static vuesupport attribute with actual user cart
    vueSupport.cart.token = getCookie('cart-token')

    await GTM.updateUser(rootState.user.currentUser)
    const name = gaEvent.eventName ? gaEvent.eventName : `User ${gaEvent.name}`
    pushDataLayerEvent(name, {
      category: 'User',
      action: gaEvent.name,
      label: gaEvent.label
    })

    await redirectToReturnPathIfPresent()
    dispatch('fetchLayoutConfig')

    commit('setAuthStep', authenticationSteps.SIGNED_IN)

    dispatch('getLineItemsCount', { resetCart: true })

    await dispatch('fetchWishlistAndWaitlist')
    if (!isNil(options.wishlistId)) dispatch('addVariantsToWishlist', [options.wishlistId])
    if (!isNil(options.waitlistId)) dispatch('addVariantsToWaitlist', [options.waitlistId])

    commit('setAuthLoading', false)
  },

  async fetchServiceAgreements ({ commit }) {
    try {
      const serviceAgreementApi = new ServiceAgreement()
      const { data } = await serviceAgreementApi.fetchServiceAgreements()

      commit('setServiceAgreements', data || [])
    } catch (error) {
      notifyBugsnag(error)
    }
  },

  async setSocialLogins ({ commit }) {
    const disabledButtons = enabledSocialLogins()

    if (disabledButtons) {
      const filteredButtons = []
      for (const [key, value] of Object.entries(disabledButtons)) {
        if (value === 'true') filteredButtons.push(key)
      }
      commit('setAvailableSocialProviders', filteredButtons)
    }
  },

  async redirectToOkta ({ _ }, { email } = {}) {
    const { codeVerifier, codeChallenge, state, codeChallengeMethod } = await authClient.token.prepareTokenParams()

    localStorage.setItem('codeVerifier', codeVerifier)
    localStorage.setItem('codeChallenge', codeChallenge)
    localStorage.setItem('userEmail', email.toLowerCase())
    localStorage.setItem('oktaState', state)

    authClient.token.getWithRedirect({
      responseType: ['code'],
      state: state,
      codeChallenge: codeChallenge,
      codeChallengeMethod: codeChallengeMethod
    })
  }
}

function authStepAfterCheckExistence (existOnline, existCrm, requestParams, finalStep) {
  if (existOnline) {
    if (oktaLoginEnabled() && isSephoraEmail(requestParams.email)) return authenticationSteps.OKTA_SIGN_IN
    if (requestParams.marketplaceType) return authenticationSteps.MARKETPLACE_SIGN_IN

    return passwordlessSignInEnabled() ? authenticationSteps.SELECT_SIGN_IN_METHOD : authenticationSteps.SIGN_IN
  } else if (existCrm) {
    return authenticationSteps.REGISTER_RETAIL
  } else {
    return isNil(finalStep) ? authenticationSteps.FINAL : finalStep
  }
}

function accountVerificationEnabled () {
  return splitio.getTreatment('enable_account_verification')
}

function enabledSocialLogins () {
  const TreatmentResult = splitio.getTreatmentWithConfig('enabled_social_login_methods')
  return (TreatmentResult.treatment === 'displayed_methods') ? JSON.parse(TreatmentResult.config) : false
}

function isSephoraEmail (email) {
  const downCaseEmail = email?.toLowerCase()
  return new RegExp(/.*@sephora\..*$/).test(downCaseEmail) || new RegExp(/.*@*\.sephora\..*$/).test(downCaseEmail)
}

function oktaLoginEnabled () {
  return splitio.getTreatment('enable_okta_login')
}

function passwordlessSignInEnabled () {
  return vueSupport?.featureFlags?.enablePasswordlessSignIn
}

export default {
  state,
  getters,
  mutations,
  actions
}
