function pushDataLayerEvent (eventName, options = {}) {
  dataLayer.push({ event: eventName, ...options })
}

function resetDataLayer () {
  dataLayer[0].product = {}
  dataLayer[0].brand = {}
  dataLayer[0].category = {}
  dataLayer[0].search = {}
  dataLayer[0].list = {}
}

function trackSearchMenuSuggestedResultClickEvent (query, entity, type) {
  pushDataLayerEvent(
    'search_menu_suggested_result_click', {
      params: {
        search_term: query,
        search_type: type,
        search_result_slug: entity.attributes['slug-url']
      }
    }
  )
}

class GTM {
  static async updateUser (user) {
    if (!user) return

    const { default: parse } = await import('date-fns/parse')
    const { default: differenceInYears } = await import('date-fns/difference_in_years')

    dataLayer.push({
      user: {
        admin: user.attributes.admin,
        age: differenceInYears(new Date(), parse(user.attributes['date-of-birth'])),
        card_number: user.attributes['card-number'],
        email_md5: user.attributes['email-md5'],
        email_sha256: user.attributes['email-sha256'],
        id: user.id,
        is_employee: /@(luxola|sephoradigital|sephora)/.test(user.attributes.email),
        loyalty_tier: user.attributes['loyalty-tier'],
        mobile_number_md5: user.attributes['mobile-phone-md5']
      }
    })
  }
}

export {
  pushDataLayerEvent,
  resetDataLayer,
  trackSearchMenuSuggestedResultClickEvent
}
export default GTM
