import Account from '@/modules/api/account'
import get from 'lodash/get'
import { getCookie } from '@/modules/services/cookies'

const accountApi = new Account()

const digitalCatalogSession = () => {
  const rawDigitalCatalogSession = getCookie('digital-catalog-session')
  return rawDigitalCatalogSession ? JSON.parse(rawDigitalCatalogSession.replaceAll('+', ' ')) : null
}

const state = {
  currentUser: false,
  currentDCUser: digitalCatalogSession()
}

const getters = {
  currentUserEmail: (state) => get(state, 'currentUser.attributes.email', '')
}

const mutations = {
  setCurrentUser (state, user) {
    state.currentUser = user
    if (!vueSupport.currentUser) vueSupport.currentUser = user
  },
  setCurrentDCUser (state, user) {
    state.currentDCUser = user || digitalCatalogSession()
  },
  destroyDCUser () {
    state.currentDCUser = null
  }
}

const actions = {
  async fetchUser ({ commit }) {
    try {
      const { data } = await accountApi.fetchUser()
      commit('setCurrentUser', data || false)
    } catch (error) {}
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
