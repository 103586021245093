import Waitlist from '@/modules/api/waitlist'
import { notifyBugsnag } from '@/modules/services/bugsnag'
import union from 'lodash/union'
import xor from 'lodash/xor'

const waitlistApi = new Waitlist()

const state = {
  waitlist: [],
  variants: [],
  displayedVariants: [],
  products: [],
  filters: [],
  waitlistLoading: false
}

const mutations = {
  setWaitlist (state, array) {
    state.waitlist = array
  },

  setWaitlistVariants (state, array) {
    state.variants = array
  },

  setWaitlistDisplayedVariants (state, array) {
    state.displayedVariants = array
  },

  setWaitlistProducts (state, array) {
    state.products = array
  },

  setWaitlistLoading (state, bool) {
    state.waitlistLoading = bool
  },

  setWaitlistFilters (state, array) {
    state.filters = array
  }
}

const actions = {
  async fetchWaitlist ({ commit, state }, args = {}) {
    commit('setWaitlistLoading', true)

    try {
      const { data, included } = await waitlistApi.fetch(args)

      if (Object.keys(args).length > 0) {
        commit('setWaitlistDisplayedVariants', data || [])
      } else {
        commit('setWaitlist', data.map(v => parseInt(v.id)))
        commit('setWaitlistVariants', data || [])
        commit('setWaitlistDisplayedVariants', data || [])
        commit('setWaitlistProducts', included || [])

        localStorage.setItem('waitlistedVariants', JSON.stringify(state.waitlist))
      }
    } catch (error) {
      notifyBugsnag(error)
    } finally {
      commit('setWaitlistLoading', false)
    }
  },

  async fetchWaitlistFilters ({ commit }) {
    try {
      const { included } = await waitlistApi.filters()

      commit('setWaitlistFilters', included || [])
    } catch (error) {
      notifyBugsnag(error)
    }
  },

  async addVariantsToWaitlist ({ commit, state }, values = []) {
    const ids = values.map(value => parseInt(value))

    try {
      await waitlistApi.add(values.join())
      commit('setWaitlist', union(state.waitlist, ids))

      localStorage.setItem('waitlistedVariants', JSON.stringify(state.waitlist))
    } catch (error) {
      notifyBugsnag(error)
    }
  },

  async removeVariantsFromWaitlist ({ commit, state }, values = []) {
    commit('setWaitlistLoading', true)
    const ids = values.map(v => parseInt(v))

    try {
      await waitlistApi.remove(values.join())
      commit('setWaitlist', xor(state.waitlist, ids))
      commit('setWaitlistVariants', state.variants.filter(variant => !ids.includes(parseInt(variant.id))))
      commit('setWaitlistDisplayedVariants', state.displayedVariants.filter(variant => !ids.includes(parseInt(variant.id))))

      localStorage.setItem('waitlistedVariants', JSON.stringify(state.waitlist))
    } catch (error) {
      notifyBugsnag(error)
    } finally {
      commit('setWaitlistLoading', false)
    }
  }
}

export default {
  state,
  mutations,
  actions
}
