import { parameterize } from '@/modules/services/utilities'
import { productsIndexDefaultPageSize, productsIndexDefaultSort } from '@/modules/config/catalogue'
import { centsToDollars } from '@/modules/services/price-support'
import { notifyBugsnag } from '@/modules/services/bugsnag'
import { filterByType, findByType } from '@/modules/api/helpers'
import ProductGraphQL from '@/modules/graphql/product'
import Product from '@/modules/api/product'
import LocationSearches from '@/modules/api/location-searches'
import { GRAPHQL_SORT_VALUES, mappingGraphQLAttributes } from '@/modules/services/graphql.js'
import pickBy from 'lodash/pickBy'
import isEmpty from 'lodash/isEmpty'

const productApi = new Product()
const locationSearchesApi = new LocationSearches()

const entityRouteQueryCustom = (state) => {
  return state.filterCustomSlugs.reduce((obj, filter) => {
    const filterTypeSlug = parameterize(filter.type.attributes.name)
    const filterValues = filter.values

    obj[filterTypeSlug] = filterValues.map(v => parameterize(v.attributes.value)).join()
    return obj
  }, {})
}

const state = {
  // show
  product: {},
  legal_info: {},
  productVariants: [],
  ad: null,
  videos: [],
  imageArticles: [],
  infoCollageArticles: [],
  currentVariant: null,
  currentVariantImageIndex: 0,
  currentQuantity: 1,
  productNotification: '',
  productPreviousState: null,
  productLoading: true,
  legalInfoLoading: true,
  productCloseUpImages: [],
  productZoomImages: [],
  filterTypes: [],
  filterValues: [],
  ingredientPreference: {},
  virtualBundle: null,
  bundleElements: [],
  installments: [],
  discountCoupons: [],
  retailStores: [],
  selectedRetailStore: null,
  locationSearch: {},
  retailStoreVariants: [],
  previousPath: '',

  // index entity
  entity: {},
  entitySlug: null,
  entityType: null,
  entityLoaded: false,
  entityFooterContent: {},
  entityPreviousRoute: null,
  showTopSection: true,

  // index products
  products: [],
  variants: [],
  ads: [],
  selectedVariants: [],
  reviews: [],
  productsCount: 0,
  productsLoading: true,
  page: 1,
  pageSize: 30,
  pageCount: 0,
  sort: 'sales',
  bestsellerProducts: [],
  bestsellerVariants: [],

  // index filters
  availableCategoryFilters: [],
  availableBrands: [],
  availableCustomFilters: [],
  availableCustomFilterValues: [],
  availableOtherFilters: [],
  availablePriceRange: {},
  filterBrandSlugs: [],
  filterPrice: {},
  filterCustomSlugs: [],
  filterOthers: [],
  showMobileSort: false,
  showViewResults: false,
  showViewResultsWithCount: false,
  filtersLoading: false,

  // index billboards
  billboards: [],
  terms: {},
  earlyAccessCtaText: '',
  earlyAccessCtaDisabled: false
}

const getters = {
  variantsSortedByName (state) {
    return state.productVariants.concat().sort((a, b) => {
      if (a.attributes.name < b.attributes.name) return -1
      if (a.attributes.name > b.attributes.name) return 1
      return 0
    })
  },

  productOptionType (state) {
    if (state.product.attributes) {
      return state.product.attributes['option-type-categories'][0] || 'type'
    } else {
      return 'type'
    }
  },

  activeVariantForProduct: (state, getters) => (product, variantId = null) => {
    let defaultVariant
    const variants = getters.variantsForProduct(product)

    if (variantId) {
      defaultVariant = variants.find(v => (v.attributes['slug-url'] === variantId) || (v.id === variantId))
    }

    if (!vueSupport.catalogueServiceEnabled) {
      defaultVariant = defaultVariant || variants.find(variant => parseInt(variant.id) === product.attributes['featured-variant-id'] && variant.attributes['inventory'] > 0)
      defaultVariant = defaultVariant || variants.find(variant => variant.attributes['inventory'] > 0)
    }

    defaultVariant = defaultVariant || variants.find(variant => parseInt(variant.id) === product.attributes['featured-variant-id'])
    defaultVariant = defaultVariant || variants[0]

    return defaultVariant
  },

  retailStoreVariants: (state, getters) => (product) => {
    return product?.attributes?.['cnc-availability-count']?.filter(variant => variant['count'] > 0)?.map((variant) => variant['variant-id']) || []
  },

  // index
  entityTypeSingular (state) {
    const entityTypeMatching = {
      'categories': 'category',
      'brands': 'brand',
      'product-groups': 'product_group',
      'landing-pages': 'landing_page',
      'search': 'search',
      'offers': 'offer'
    }
    return entityTypeMatching[state.entityType]
  },

  entityTypeUnderscored (state) {
    const entityTypeMatching = {
      'categories': 'categories',
      'brands': 'brands',
      'product-groups': 'product_groups',
      'landing-pages': 'landing_pages',
      'offers': 'offers'
    }
    return entityTypeMatching[state.entityType]
  },

  entityPath: (state) => (categorySlug = null, query = {}) => {
    let entityRoute = {}

    switch (state.entityType) {
      case 'categories':
        entityRoute = {
          name: 'categories-show',
          params: {
            id: (categorySlug ? categorySlug.split('/') : state.entitySlug.split('/'))
          }
        }
        break
      case 'brands':
        entityRoute = {
          name: 'brands-show',
          params: {
            id: state.entitySlug,
            ...(categorySlug ? { category: categorySlug.split('/') } : undefined)
          }
        }
        break
      case 'product-groups':
        entityRoute = {
          name: 'product-groups-show',
          params: {
            id: state.entitySlug,
            ...(categorySlug ? { category: categorySlug.split('/') } : undefined)
          }
        }
        break
      case 'landing-pages':
        if (/sale-(sg|au|nz|hk|my|ph|id|th)$/.test(state.entitySlug) || state.entitySlug === 'sale') {
          entityRoute = {
            name: 'landing-pages-sale',
            params: {
              ...(categorySlug ? { category: categorySlug.split('/') } : undefined)
            }
          }
        } else {
          entityRoute = {
            name: 'landing-pages-show',
            params: {
              id: state.entitySlug,
              ...(categorySlug ? { category: categorySlug.split('/') } : undefined)
            }
          }
        }
        break
      case 'offers':
        entityRoute = {
          name: 'offers-show',
          params: {
            id: state.entitySlug,
            ...(categorySlug ? { category: categorySlug.split('/') } : undefined)
          }
        }
        break
      case 'search':
        query = Object.assign({
          q: state.entitySlug
        }, query)

        entityRoute = {
          name: 'search-show',
          params: {
            ...(categorySlug ? { category: categorySlug.split('/') } : undefined)
          }
        }
    }

    if (Object.keys(query).length > 0) {
      entityRoute = Object.assign({}, entityRoute, {
        query: query
      })
    }

    return entityRoute
  },

  entityRouteWithQuery: (state, getters, rootState) => ({ mergeQuery = {}, mergeHash = '' } = {}) => {
    const customFilters = state.availableCustomFilters.map(filter => parameterize(filter.attributes.name))
    // this will remove all custom filter type queries from mergeQuery
    const queryWithoutCustomFilters = pickBy(mergeQuery, (value, key) => !customFilters.includes(key))
    const route = Object.assign({}, getters.entityPath(rootState.category.filterCategorySlug), {
      query: {
        ...queryWithoutCustomFilters,
        q: state.entityType === 'search' ? state.entitySlug : undefined,
        page: state.page !== 1 ? state.page : undefined,
        view: state.pageSize !== productsIndexDefaultPageSize ? state.pageSize : undefined,
        sort: state.sort !== productsIndexDefaultSort(state.entityType) ? state.sort : undefined,
        brands: state.filterBrandSlugs.length > 0 ? state.filterBrandSlugs.join() : undefined,
        'min-price': state.filterPrice.min ? centsToDollars(state.filterPrice.min) : undefined,
        'max-price': state.filterPrice.max ? centsToDollars(state.filterPrice.max) : undefined,
        ...entityRouteQueryCustom(state),
        // Handle SEO friendly "sale" filter
        other: state.filterOthers.length > 0 ? state.filterOthers.map((filterOther) => filterOther === 'on_sale' ? 'sale' : filterOther).join() : undefined
      },
      hash: mergeHash
    })

    return route
  },

  entityRouteCanonical (state, getters, rootState) {
    const route = Object.assign({}, getters.entityPath(rootState.category.filterCategorySlug), {
      query: {
        ...entityRouteQueryCustom(state)
      }
    })

    return route
  },

  variantsForProduct: (state) => (product) => {
    let variants

    if (state.productVariants.length > 0 && product.id === state.product.id) {
      variants = state.productVariants.filter(variant => variant.relationships.product.data.id === product.id)
    }
    if (state.variants.length > 0) {
      variants = variants || state.variants.filter(variant => variant.relationships.product.data.id === product.id)
    }

    return variants || []
  },

  variantsCountForProduct: (state, getters) => (product) => {
    return getters.variantsForProduct(product).length
  },

  activeCategory (state, rootGetters) {
    if (state.entityType === 'categories') { // TODO: Recheck
      return state.entity
    } else {
      return rootGetters.filterCategory
    }
  },

  activeCategoryPage: (state, rootGetters, rootState) => (tier) => {
    if ((Object.keys(rootState.category.filterCategoryTrail[tier]).length === 0 && state.entityType === 'categories')) {
      return state.entity
    } else {
      return rootState.category.filterCategoryTrail[tier]
    }
  },

  activeFirstLevelCategories: (state, rootGetters) => {
    if (!state.entityLoaded) { return [] }

    const categories = rootGetters.topLevelCategories
    if (state.entityType === 'categories') {
      // return all available categories in the first entity
      return categories.filter(category => {
        if (state.entity.attributes['parent-id'] === null) {
          return category.id === state.entity.id
        } else {
          return category.id === rootGetters.ancestorCategoryForId(state.entity.id)
        }
      })
    } else {
      return state.availableCategoryFilters.filter(category => category.attributes['parent-id'] === null)
    }
  },

  showAvailableSubcategoriesForCategory: (state, getters, rootState, rootGetters) => (parent) => {
    let activeCategoryAndParentIds
    if (state.entityType === 'categories') {
      activeCategoryAndParentIds = rootGetters.parentIdsForCategory(getters.activeCategory)
    } else {
      if (!rootState.category.filterCategorySlug) { return false }
      activeCategoryAndParentIds = rootGetters.parentIdsForCategory(getters.activeCategory)
    }
    if (!activeCategoryAndParentIds) { return false }
    return activeCategoryAndParentIds.split('/').indexOf(parent.id) !== -1
  },

  mobileBillboards (state) {
    return state.billboards.filter(billboard => billboard.attributes['mobile-only'] === true)
  },

  filterValuesFor: (state) => (type) => {
    const filterValueIds = type.relationships['filter-values'].data.map(v => v.id)
    return state.availableCustomFilterValues.filter(filterValue => filterValueIds.indexOf(filterValue.id) !== -1)
  },

  filterTypeFor: (state) => (valueId) => {
    return state.availableCustomFilters.find(filterType => filterType.relationships['filter-values'].data.find(v => v.id === valueId))
  },

  productForVariant: (state) => (variant) => {
    if (state.products.length > 0) {
      return state.products.find(product => product.id === variant.relationships.product.data.id)
    }
  },

  currentVariantFilterValues (state) {
    if (state.currentVariant.relationships['filter-values']) {
      const variantFilterValuesIds = state.currentVariant.relationships['filter-values'].data.map(fv => fv.id)
      return state.filterValues.filter(fv => variantFilterValuesIds.includes(fv.id))
    }

    return []
  },

  currentVariantFilterValuesForFilterType: (state, getters) => (filterTypeId) => {
    const variantFilterValues = getters.currentVariantFilterValues
    return variantFilterValues.filter(fv => fv.attributes['filter-type-id'].toString() === filterTypeId)
  }
}

const mutations = {
  setProduct (state, product) {
    state.product = product
  },

  setSelectedRetailStore (state, retailStore) {
    state.selectedRetailStore = retailStore
  },

  setLegalInfo (state, legalInfo) {
    state.legal_info = legalInfo
  },

  setProductVariants (state, array) {
    state.productVariants = array
  },

  setAd (state, ad) {
    state.ad = ad
  },

  setReviews (state, reviews) {
    state.reviews = reviews
  },

  setRetailStores (state, retailStores) {
    state.retailStores = retailStores
  },

  setVideos (state, videos) {
    state.videos = videos
  },

  setProductCloseUpImages (state, productCloseUpImages) {
    state.productCloseUpImages = productCloseUpImages
  },

  setProductZoomImages (state, productZoomImages) {
    state.productZoomImages = productZoomImages
  },

  setImageArticles (state, imageArticles) {
    state.imageArticles = imageArticles
  },

  setInfoCollageArticles (state, infoCollageArticles) {
    state.infoCollageArticles = infoCollageArticles
  },

  setCurrentQuantity (state, quantity) {
    state.currentQuantity = quantity
  },

  setCurrentVariant (state, variant) {
    state.currentVariant = variant
  },

  setCurrentVariantImageIndex (state, index = 0) {
    state.currentVariantImageIndex = index
  },

  setWaitlistedVariant (state, bool) {
    state.currentVariant.attributes['waitlisted'] = bool
  },

  setProductNotification (state, string) {
    state.productNotification = string
  },

  setProductPreviousState (state, object) {
    state.productPreviousState = object
  },

  setFilterTypes (state, array) {
    state.filterTypes = array
  },

  setFilterValues (state, array) {
    state.filterValues = array
  },

  setIngredientPreference (state, object) {
    state.ingredientPreference = object
  },

  setVirtualBundle (state, object) {
    state.virtualBundle = object
  },

  setBundleElements (state, array) {
    state.bundleElements = array
  },

  setProductDiscountCoupons (state, array) {
    state.discountCoupons = array
  },

  // index entity
  setEntity (state, object) {
    state.entity = object
  },

  setEntitySlug (state, id) {
    // When the entity type is categories, id returned is an array
    if (Array.isArray(id)) {
      id = id.join('/')
    }
    state.entitySlug = id
  },

  setEntityType (state, string) {
    state.entityType = string
  },

  setEntityLoaded (state, boolean) {
    state.entityLoaded = boolean
  },

  setEntityFooterContent (state, string) {
    state.entityFooterContent = string
  },

  setEntityPreviousRoute (state, object) {
    state.entityPreviousRoute = object
  },

  setProductLoading (state, boolean) {
    state.productLoading = boolean
  },

  setLegalInfoLoading (state, boolean) {
    state.legalInfoLoading = boolean
  },

  setInstallments (state, array) {
    state.installments = array
  },

  // index products
  setProducts (state, array) {
    state.products = array
  },

  setVariants (state, array) {
    state.variants = array
  },

  setAds (state, array) {
    state.ads = array
  },

  setProductsCount (state, integer) {
    state.productsCount = integer
  },

  setProductsLoading (state, boolean) {
    state.productsLoading = boolean
  },

  setPage (state, integer) {
    state.page = integer
  },

  setPageSize (state, integer) {
    state.pageSize = integer
  },

  setPageCount (state, integer) {
    state.pageCount = integer
  },

  setSort (state, string) {
    state.sort = string
  },

  setSelectedVariants (state, array) {
    state.selectedVariants = array
  },

  setBestsellerProducts (state, array) {
    state.bestsellerProducts = array
  },

  setBestsellerVariants (state, object) {
    state.bestsellerVariants = object
  },

  // index filters
  setAvailableCategoryFilters (state, array) {
    state.availableCategoryFilters = array
  },

  setAvailableBrands (state, array) {
    state.availableBrands = array
  },

  setAvailableOtherFilters (state, array) {
    state.availableOtherFilters = array
  },

  setFilterBrandSlugs (state, brandSlugs) {
    state.filterBrandSlugs = brandSlugs
  },

  toggleFilterBrandSlugs (state, brand) {
    const position = state.filterBrandSlugs.indexOf(brand)
    if (position === -1) {
      state.filterBrandSlugs.push(brand)
      state.filterBrandSlugs.sort()
    } else {
      state.filterBrandSlugs.splice(position, 1)
    }
    state.page = 1
    state.showTopSection = false
    state.showViewResults = true
  },

  setAvailablePriceRange (state, object) {
    state.availablePriceRange = object
  },

  setFilterPrice (state, object) {
    state.filterPrice = object
  },

  setFilterMinPrice (state, price) {
    state.filterPrice.min = parseInt(price)
  },

  setFilterMaxPrice (state, price) {
    state.filterPrice.max = parseInt(price)
  },

  toggleFilterPrice (state, price) {
    if (state.filterPrice !== price) {
      state.filterPrice = price
    } else {
      state.filterPrice = {}
    }
    state.page = 1
    state.showTopSection = false
    state.showViewResults = true
  },

  setFilterOthers (state, array) {
    state.filterOthers = array
  },

  toggleFilterOthers (state, value) {
    const position = state.filterOthers.indexOf(value)
    if (position === -1) {
      state.filterOthers.push(value)
    } else {
      state.filterOthers.splice(position, 1)
    }
    state.page = 1
    state.showTopSection = false
    state.showViewResults = true
  },

  setAvailableCustomFilters (state, array) {
    state.availableCustomFilters = array
  },

  setAvailableCustomFilterValues (state, array) {
    state.availableCustomFilterValues = array
  },

  setFilterCustomSlugs (state, object) {
    state.filterCustomSlugs = object
  },

  setShowMobileSort (state, boolean) {
    state.showMobileSort = boolean
  },

  setShowViewResults (state, boolean) {
    state.showViewResults = boolean
  },

  setShowViewResultsWithCount (state, boolean) {
    state.showViewResultsWithCount = boolean
  },

  setFiltersLoading (state, boolean) {
    state.filtersLoading = boolean
  },

  // index billboards
  setBillboards (state, array) {
    state.billboards = array
  },

  setTerms (state, object) {
    state.terms = object
  },

  setLocationSearch (state, object) {
    state.locationSearch = object
  },

  setRetailStoreVariants (state, array) {
    state.retailStoreVariants = array
  },

  setEarlyAccessCtaText (state, text) {
    state.earlyAccessCtaText = text
  },

  setEarlyAccessCtaDisabled (state, boolean) {
    state.earlyAccessCtaDisabled = boolean
  },

  setPreviousPath (state, path) {
    state.previousPath = path
  }
}

const actions = {
  async fetchProduct ({ commit, getters }, args) {
    commit('setPrerenderStatusCode', '')
    commit('setProductLoading', true)

    try {
      const { data, included, meta } = await productApi.fetchProduct(args.id, { variantId: args.variantId })
      commit('setProduct', data)
      // load notification message first in case of error when there is no included data
      if (meta && meta.message) {
        commit('setProductNotification', meta.message)
      }
      commit('setProductVariants', filterByType(included, 'variants'))
      commit('setAd', findByType(included, 'ads'))
      commit('setVideos', filterByType(included, 'product-articles', item => item.attributes['article-type'] === 'video'))
      commit('setImageArticles', filterByType(included, 'product-articles', item => item.attributes['article-type'] === 'image'))
      commit('setInfoCollageArticles', filterByType(included, 'product-articles', item => item.attributes['article-type'] === 'info_collage'))
      commit('setFilterTypes', filterByType(included, 'filter-types'))
      commit('setFilterValues', filterByType(included, 'filter-values'))
      commit('setVirtualBundle', findByType(included, 'virtual-bundles'))
      commit('setBundleElements', filterByType(included, 'bundle-elements'))

      const ingredientPreference = findByType(included, 'ingredient-preferences')

      if (ingredientPreference) {
        commit('setIngredientPreference', ingredientPreference)
      }

      const availableVariants = getters.retailStoreVariants(data)
      commit('setRetailStoreVariants', availableVariants)
    } catch {
      if (isEmpty(state.product)) { commit('setPrerenderStatusCode', '404') }
    }

    commit('setProductLoading', false)
  },

  async fetchLegalInfo ({ commit }, args) {
    commit('setLegalInfoLoading', true)
    try {
      const { data } = await productApi.fetchLegalInfo(args.id)
      commit('setLegalInfo', data)
    } catch {}

    commit('setLegalInfoLoading', false)
  },

  async fetchInstallments ({ commit }, args) {
    const { data } = await productApi.fetchInstallments(args.id)
    const installmentDetails = data.map(variantInstallmentDetails =>
      ({ id: variantInstallmentDetails.id, details: variantInstallmentDetails.attributes['installment-details'] }))
    commit('setInstallments', Array.isArray(data) ? installmentDetails : [])
  },

  async fetchProductDiscountCoupons ({ commit }, args) {
    try {
      const { data } = await productApi.fetchDiscountCoupons(args.id)
      commit('setProductDiscountCoupons', data)
    } catch (error) {
      notifyBugsnag(error)
    }
  },

  async fetchRetailStores ({ commit }, { queryParams, id }) {
    try {
      const { data } = await productApi.fetchRetailStores(id, queryParams)
      commit('setRetailStores', data)
    } catch (error) {
      notifyBugsnag(error)
    }
  },

  // index
  fetchEntity ({ commit, state, getters }) {
    const url = `/api/v2.4/${getters.entityTypeUnderscored}/${state.entitySlug}?include=billboards,sidebar_content,terms_and_conditions,billboard_configurations,best_selling_products`
    // Reset billboards to prevent old ones from staying on screen while page is loading
    commit('setBillboards', [])
    // Reset entity to prevent it from being incorrectly used for computed properties
    commit('setEntity', {})
    // Reset bestsellers for proper loading
    commit('setBestsellerProducts', [])
    commit('setBestsellerVariants', [])

    return window.axios.get(url).then((response) => {
      commit('setEntity', response.data.data)

      if (response.data.included) {
        commit('setEntityFooterContent', response.data.included.find(item => item.type === 'sidebar-contents'))
        commit('setBillboards', response.data.included.filter(item => item.type === 'billboards' || item.type === 'billboard-configurations'))
        commit('setTerms', response.data.included.find(item => item.type === 'term-and-conditions'))
        commit('setBestsellerProducts', response.data.included.filter(item => item.type === 'products'))
        commit('setBestsellerVariants', response.data.included.filter(item => (item.type === 'variants' && (item.attributes['icon-url'] || item.attributes['icon-image-urls'][0]) !== undefined)))
      } else {
        commit('setEntityFooterContent', {})
        commit('setBillboards', [])
        commit('setTerms', {})
        commit('setBestsellerProducts', [])
        commit('setBestsellerVariants', [])
      }
      commit('setEntityLoaded', true)
    })
  },

  fetchProducts ({ commit, state, getters, rootState }, args) {
    commit('setPrerenderStatusCode', '')
    commit('setProductsLoading', true)

    let url

    if (state.entityType === 'search') {
      url = `/api/v2.3/search/products?query=${encodeURIComponent(state.entitySlug)}`
    } else if (state.entityType === 'landing-pages' && rootState.landingPage.layout === 'variant_selection') {
      url = `/api/v2.4/variants?filter[${getters.entityTypeSingular}]=${state.entitySlug}`
    } else {
      url = `/api/v2.3/products?filter[${getters.entityTypeSingular}]=${state.entitySlug}`
    }

    url += `&page[size]=${state.pageSize}&page[number]=${state.page}`

    // When on the search page and sorting by the default sort value (relevance), the sort parameter should not be added
    if (!(state.entityType === 'search' && state.sort === productsIndexDefaultSort(state.entityType))) {
      url += `&sort=${state.sort}`
    }

    if (state.entityType === 'landing-pages' && rootState.landingPage.layout === 'variant_selection') {
      url += '&include=products,brands,product.variants'
    } else {
      url += `&include=variants,brand,featured_ad`
    }

    if (rootState.category.filterCategorySlug) {
      url += `&where[category]=${rootState.category.filterCategorySlug}`
    }
    if (state.filterBrandSlugs.length > 0) {
      url += `&where[brand]=${state.filterBrandSlugs.join()}`
    }
    if (state.filterPrice) {
      if (state.filterPrice.min) { url += `&where[min_price]=${state.filterPrice.min}` }
      if (state.filterPrice.max) { url += `&where[max_price]=${state.filterPrice.max}` }
    }
    if (state.entityType === 'categories' && state.filterCustomSlugs.length > 0) {
      state.filterCustomSlugs.forEach(filter => {
        const filterTypeId = filter.type.id
        const filterValueIds = filter.values.map(v => v.id).join()
        url += `&where[filter_type][${filterTypeId}]=${filterValueIds}`
      })
    }
    if (state.entityType === 'landing-pages' && state.entitySlug === 'sale') {
      url += `&filter[without_oos]=true&filter[others]=on_sale`
    }
    if (state.filterOthers.length > 0) {
      state.filterOthers.forEach(filter => {
        url += `&where[others]=${filter}`
      })
    }

    return window.axios.get(url).then((response) => {
      if (state.entityType === 'landing-pages' && rootState.landingPage.layout === 'variant_selection') {
        commit('setSelectedVariants', response.data.data)
        commit('setProducts', (response.data.included || []).filter(item => item.type === 'products'))
      } else {
        commit('setSelectedVariants', [])
        commit('setProducts', response.data.data)
      }
      commit('setAds', (response.data.included || []).filter(item => item.type === 'ads'))
      commit('setProductBrands', (response.data.included || []).filter(item => item.type === 'brands'))
      commit('setVariants', (response.data.included || []).filter(item => (item.type === 'variants' && (item.attributes['icon-url'] || item.attributes['icon-image-urls'][0]) !== undefined)))

      commit('setProductsCount', response.data.meta['total'])
      commit('setPageCount', response.data.meta['total-pages'])
      commit('setProductsLoading', false)
      if (state.productsCount === 0) { commit('setPrerenderStatusCode', '404') }
    }).catch(() => {
      luxola.updateFlash('An error has occured while loading the page. Please refresh or try again later!')
      commit('setProductsLoading', false)
    })
  },

  async fetchProductsGraphql ({ commit, state, getters, rootState }, args) {
    const productGraphQL = new ProductGraphQL()
    commit('setProductsLoading', true)

    const filterBy = {}
    if (rootState.category.filterCategorySlug) { filterBy['categories'] = [rootState.category.filterCategorySlug] }
    if (state.filterBrandSlugs.length > 0) { filterBy['brands'] = state.filterBrandSlugs }
    if (state.filterPrice) {
      filterBy['price'] = {}
      if (state.filterPrice.min) { filterBy['price']['minCents'] = state.filterPrice.min }
      if (state.filterPrice.max) { filterBy['price']['maxCents'] = state.filterPrice.max }
    }
    if (state.entityType === 'categories' && state.filterCustomSlugs.length > 0) {
      filterBy['customFilters'] = []
      state.filterCustomSlugs.forEach(filter => {
        filterBy['customFilters'].push({ 'type': filter.type.id, 'values': filter.values.map(v => v.id) })
      })
    }
    if (state.filterOthers.length > 0) {
      state.filterOthers.forEach(filter => {
        if (filter === 'on_sale') { filterBy['onSale'] = true }
      })
    }

    try {
      const response = await productGraphQL.fetchProducts(
        getters.entityTypeSingular.toUpperCase(),
        state.entitySlug,
        filterBy,
        GRAPHQL_SORT_VALUES[state.sort],
        state.pageSize,
        state.page
      )
      const { data, included } = mappingGraphQLAttributes(response.data)

      commit('setSelectedVariants', [])
      commit('setProducts', data)
      commit('setAds', filterByType(included, 'ads'))
      commit('setProductBrands', filterByType(included, 'brands'))
      commit('setVariants', filterByType(included, 'variants', item => {
        return (item.attributes['icon-url'] || item.attributes['icon-image-urls'][0]) !== undefined
      }))
      commit('setProductsCount', response.data.products.pageInfo.totalCount)
      commit('setPageCount', response.data.products.pageInfo.endPage)
    } catch (error) {
      notifyBugsnag(error)
      commit('setNotification', {
        type: 'error',
        msg: 'An error has occured while loading the page. Please refresh or try again later!'
      })
    }
    commit('setProductsLoading', false)
  },

  fetchFilters ({ commit, state, getters, rootState }) {
    commit('setFiltersLoading', true)

    const slug = state.entityType === 'search' ? encodeURIComponent(state.entitySlug) : state.entitySlug
    let url = ''

    if (state.entityType === 'landing-pages' && rootState.landingPage.layout === 'variant_selection') {
      url = `/api/v2.3/variants/filters?filter[${getters.entityTypeSingular}]=${slug}&include=brands,categories`
    } else {
      url = `/api/v2.3/products/filters?filter[${getters.entityTypeSingular}]=${slug}&include=brands,categories,filter_types.filter_values`
    }

    return window.axios.get(url).then((response) => {
      commit('setAvailableBrands', response.data.included.filter(item => item.type === 'brands'))
      commit('setAvailableCustomFilters', response.data.included.filter(item => item.type === 'filter-types'))
      commit('setAvailableCustomFilterValues', response.data.included.filter(item => item.type === 'filter-values'))
      commit('setAvailableOtherFilters', response.data.data.attributes['other-filters'])
      commit('setAvailablePriceRange', {
        max: response.data.data.attributes['max-price'],
        min: response.data.data.attributes['min-price']
      })

      if (state.entityType !== 'categories') {
        commit('setAvailableCategoryFilters', response.data.included.filter(item => item.type === 'categories'))
      }

      commit('setFiltersLoading', false)
    }).catch(() => {
      commit('setFiltersLoading', false)
    })
  },

  fetchWishlistAndWaitlist ({ commit, dispatch }) {
    try {
      const storageWishlist = JSON.parse(localStorage.getItem('wishlistedVariants'))
      const storageWaitlist = JSON.parse(localStorage.getItem('waitlistedVariants'))

      if (storageWishlist) {
        commit('setWishlist', storageWishlist)
      } else {
        dispatch('fetchWishlistVariants')
      }

      if (storageWaitlist) {
        commit('setWaitlist', storageWaitlist)
      } else {
        dispatch('fetchWaitlist')
      }
    } catch (error) {
      dispatch('fetchWishlistVariants')
      dispatch('fetchWaitlist')
    }
  },

  resetAllFilters ({ commit, state }) {
    commit('setFilterCategorySlug', '', { root: true })
    state.filterBrandSlugs = []
    state.filterPrice = {}
    state.filterCustomSlugs = []
    state.filterOthers = []
    state.page = 1
    state.showTopSection = true
    state.showViewResults = true
  },

  assignFilterCustomSlugs ({ commit, getters }, object) {
    const filters = []

    if (object) {
      for (const filterTypeSlug of Object.keys(object)) {
        const filterType = state.availableCustomFilters.find(f => parameterize(f.attributes.name) === filterTypeSlug)

        if (!filterType) { continue }

        const availableFilterValues = getters.filterValuesFor(filterType)
        const filterValueSlugs = object[filterTypeSlug].split(',')
        const filterValues = availableFilterValues.filter(f => filterValueSlugs.indexOf(parameterize(f.attributes.value)) !== -1)

        if (filterValues.length === 0) { continue }

        filters.push({
          type: filterType,
          values: filterValues
        })
      }
    }
    const orderedFilters = []
    filters.map(f => f.type.attributes.name).sort().forEach((filterTypeName) => {
      orderedFilters.push(filters.find(f => f.type.attributes.name === filterTypeName))
    })
    commit('setFilterCustomSlugs', orderedFilters)
  },

  toggleFilterCustomSlugs ({ state, getters }, { type, value }) {
    const filters = state.filterCustomSlugs.concat()

    const filter = filters.find(f => f.type.id === type.id)
    const availableFilterType = state.availableCustomFilters.find(v => v.id === type.id)

    if (filter) {
      // Filter type has already been selected
      const filterIndex = filters.indexOf(filter)
      const filterValueIndex = filter.values.findIndex(v => v.id === value.id)

      if (filterValueIndex === -1) {
        // Filter value not yet selected, add it to the filters
        filters[filterIndex].values.push(value)
        filters[filterIndex].values.sort((a, b) => {
          if (a.attributes.value < b.attributes.value) {
            return -1
          } else {
            return 1
          }
        })
      } else {
        // Filter value has already been selected, remove it from the filter type values
        filters[filterIndex].values.splice(filterValueIndex, 1)

        // No more values left in the filter type, remove it from filters
        if (filters[filterIndex].values.length === 0) {
          filters.splice(filterIndex, 1)
        }
      }
    } else if (availableFilterType) {
      // Filter type not yet selected, add it and its value to the filters
      const availableFilterValues = getters.filterValuesFor(availableFilterType)
      const filterValue = availableFilterValues.find(f => f.id === value.id)

      filters.push({
        type: availableFilterType,
        values: [filterValue]
      })
    }

    const orderedFilters = []
    filters.map(f => f.type.attributes.name).sort().forEach((filterTypeName) => {
      orderedFilters.push(filters.find(f => f.type.attributes.name === filterTypeName))
    })
    state.filterCustomSlugs = orderedFilters
    state.page = 1
    state.showTopSection = false
    state.showViewResults = true
  },

  async fetchLocationSearch ({ commit }) {
    try {
      const { data } = await locationSearchesApi.recent()
      if (!data?.attributes) return

      commit('setLocationSearch', data.attributes)
    } catch {}
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
