import get from 'lodash/get'
import GlobalConfigService from '@/modules/api/global-config'

const globalConfigApi = new GlobalConfigService()

const state = {
  data: {},
  applePaySupportedNetworks: {}
}

const getters = {
  currentGlobalConfig (state) {
    const isoAlpha2Code = get(vueSupport, 'currentlySelectedCountry.iso_alpha2_code', '')
    if (isoAlpha2Code && state.data[isoAlpha2Code]) return state.data[isoAlpha2Code]

    const countryCode = get(vueSupport, 'countryCode', '').toLowerCase()
    if (countryCode && state.data[countryCode]) return state.data[countryCode]

    return {}
  },
  applePaySupportedNetworks: state => state.data[vueSupport.countryCode.toLowerCase()].applepaySupportedNetworks,
  googlepaySupportedNetworks: state => state.data[vueSupport.countryCode.toLowerCase()].googlepaySupportedNetworks
}

const mutations = {
  SET_GLOBAL_CONFIG (state, globalConfig) {
    state.data = globalConfig
  }
}

const actions = {
  async initializeGlobalConfig ({ commit }) {
    const response = await globalConfigApi.fetch()
    if (response.success) {
      await commit('SET_GLOBAL_CONFIG', response.data)
    } else {
      await commit(
        'setNotification',
        { msg: response.message, type: 'error' },
        { root: true }
      )
    }
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
