import secrets from '@/modules/config/secrets'
import splitio from '@/modules/services/split-io'

let bugsnag
if (vueSupport.env === 'test') {
  bugsnag = require('@bugsnag/browser')
} else {
  bugsnag = require('@bugsnag/js')
}

const bugsnagClient = bugsnag({
  apiKey: secrets.bugsnagApiKey,
  releaseStage: vueSupport.env,
  notifyReleaseStages: ['production', 'staging'],
  beforeSend: function (report) {
    if (splitio.getTreatment('enable_frontend_bugsnag') === 'off') {
      return false
    }
  }
})

function notifyBugsnag (error) {
  bugsnagClient.notify(error)
}

export {
  bugsnagClient,
  notifyBugsnag
}
