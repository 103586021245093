import Layout from '@/modules/api/layout'

const state = {
  hasAndroidApp: false,
  hasIosApp: false,
  platform: '',
  seoDeeplinkUrl: '',
  seoMetaTitle: '',
  seoMetaDescription: '',
  seoMetaKeywords: '',
  seoOgTitle: '',
  seoOgType: '',
  seoOgUrl: '', // Also used as "canonical" url
  seoOgImage: '',
  notification: {},
  seoUpdate: false,
  showAdminNav: false,
  showSidebar: false,
  showMobileFilter: false,
  showImageZoom: false,
  showLanguageOptions: false,
  showNotification: false,
  showAuthPopup: false,
  isOffline: false,
  renderSidebar: false,
  renderBelowTheFold: false,
  renderAuthentication: false,
  routerNavigation: false,
  getAppDesktopDropdown: null,
  sitewideBanner: null,
  visitorIsBot: false,
  featuredContentHeader: '',
  prerenderStatusCode: '',
  branchMetaContent: ''
}

const getters = {
  isMobileApp (state) {
    return state.platform === 'mobile_app'
  },

  isMobileWeb (state) {
    return state.platform === 'mobile_web'
  },

  isWeb (state) {
    return state.platform === 'web'
  }
}

const mutations = {
  setSeoDeeplinkUrl (state, string) {
    state.seoDeeplinkUrl = string
  },

  setSeoMetaTitle (state, string) {
    state.seoMetaTitle = string
  },

  setSeoMetaDescription (state, string) {
    state.seoMetaDescription = string
  },

  setSeoMetaKeywords (state, string) {
    state.seoMetaKeywords = string
  },

  setSeoOgTitle (state, string) {
    state.seoOgTitle = string
  },

  setSeoOgType (state, string) {
    state.seoOgType = string
  },

  setSeoOgUrl (state, string) {
    state.seoOgUrl = string
  },

  setSeoOgImage (state, string) {
    state.seoOgImage = string
  },

  setSeoUpdate (state, boolean) {
    state.seoUpdate = boolean
  },

  setNavBrand (state, brands) {
    state.navBrands = brands
  },

  setShowAdminNav (state, bool) {
    state.showAdminNav = bool
  },

  setShowSidebar (state, bool) {
    state.showSidebar = bool
  },

  setRenderSidebar (state, bool) {
    state.renderSidebar = bool
  },

  setRenderBelowTheFold (state, bool) {
    state.renderBelowTheFold = bool
  },

  setRenderAuthentication (state, bool) {
    state.renderAuthentication = bool
  },

  setShowLanguageOptions (state, bool) {
    state.showLanguageOptions = bool
  },

  setNotification (state, object) {
    state.notification = object
    state.showNotification = true
  },

  setShowNotification (state, bool) {
    state.showNotification = bool
  },

  setIsOffline (state, bool) {
    state.isOffline = bool
  },

  platform (state, platform) {
    state.platform = platform
  },

  setRouterNavigation (state, bool) {
    state.routerNavigation = bool
  },

  setGetAppDesktopDropdown (state, object) {
    state.getAppDesktopDropdown = object
  },

  setSitewideBanner (state, object) {
    state.sitewideBanner = object
  },

  setVisitorIsBot (state, bool) {
    state.visitorIsBot = bool
  },

  setFeaturedContentHeader (state, string) {
    state.featuredContentHeader = string
  },

  setShowMobileFilter (state, boolean) {
    state.showMobileFilter = boolean
  },

  setShowImageZoom (state, bool) {
    state.showImageZoom = bool
  },

  setShowAuthPopup (state, bool) {
    state.showAuthPopup = bool
  },

  setPrerenderStatusCode (state, string) {
    state.prerenderStatusCode = string
  },

  setHasIosApp (state, bool) {
    state.hasIosApp = bool
  },

  setHasAndroidApp (state, bool) {
    state.hasAndroidApp = bool
  },

  setBranchMetaContent (state, string) {
    state.branchMetaContent = string
  }
}

const actions = {
  async fetchLayoutConfig ({ commit }) {
    const layoutApi = new Layout()

    try {
      const { data } = await layoutApi.fetchLayoutConfig()
      commit('setVisitorIsBot', data.attributes['visitor-is-bot'])
      commit('setFeaturedContentHeader', data.attributes['featured-content-header'])
      commit('setHasIosApp', data.attributes['has-ios-app'])
      commit('setHasAndroidApp', data.attributes['has-android-app'])
      commit('setGetAppDesktopDropdown', data.attributes['get-app-desktop-dropdown'])
      commit('setSitewideBanner', data.attributes['header-banner'])
    } catch (error) {}
  }
}

export default {
  state,
  mutations,
  getters,
  actions
}
