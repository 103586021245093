const au = {
  country: 'Australia',
  domain: 'www.sephora.com.au',
  currency: {
    symbol: '$',
    step: '10'
  },
  points: {
    conversionRate: '1'
  },
  meta: {
    phoneNumber: '+61 2 8880 9446',
    emailCustomerService: 'contact@sephora.com.au',
    addressCustomerService: '',
    callUsBetweenDaysAndTimings: '',
    description: 'Sephora, where beauty beats. Discover the best in makeup, skincare, hair care and more from a wide selection of ​international cult beauty brands. Shop now.'
  },
  app: {
    android: {
      header: 'AU_Header_Android',
      footer: 'AU_Footer_Android'
    },
    ios: {
      header: 'AU_Header_iOS',
      footer: 'AU_Footer_iOS'
    }
  },
  social: {
    facebook: 'SephoraAus',
    twitter: 'sephoraaus',
    instagram: 'sephoraaus',
    youtube: 'channel/UCDS72PS6KZnJeYeLlgpIEtg',
    snapchat: 'sephoraau',
    hashtag: '#SephoraAU'
  },
  faq: {
    links: {
      beautyPassSection: '360004948411-SEPHORA-BEAUTY-PASS',
      deliveryOptionsAndShipping: '/faqs/360004954951-DELIVERY/360029644212-What-are-your-delivery-options-and-how-much-is-shipping',
      orders: '/faqs/360004939831-Orders',
      reviews: '/faqs/360004928232-OTHERS/360029933331-Why-was-my-review-rejected',
      returnsAndExchange: '/faqs/360004947211-Refunds-Returns-and-Exchange',
      payment: '/faqs/360004947191-Payment',
      delivery: '/faqs/360004954951-Delivery',
      deliveryAttempted: '/faqs/360004954951-Delivery/360029644452-I-was-not-am-not-available-to-receive-my-parcel-upon-delivery'
    }
  },
  signInBanner: {
    heading: 'Sephora Beauty Pass Members Enjoy More!',
    messages: [
      'Unlock exclusive online offers',
      'Access to members-only sales and events',
      'Redeem points to get discounts and gifts'
    ],
    andMoreText: 'And more!',
    signIn: 'Sign In',
    register: 'Sign Up'
  },
  terms: {
    companies: 'Sephora Australia Pty Ltd, Sephora Digital SEA Pte Ltd'
  }
}

const bd = {
  country: 'Bangladesh',
  domain: 'bd.sephora.asia',
  currency: {
    symbol: 'S$', // Needs to be in base currency (S$) for Global-e JS to dynamically convert prices
    step: '10',
    originalSymbol: 'Tk‌'
  },
  points: {
    conversionRate: '65'
  },
  meta: {
    phoneNumber: '',
    emailCustomerService: '',
    addressCustomerService: '',
    callUsBetweenDaysAndTimings: '',
    description: 'Sephora, where beauty beats. Discover the best in makeup, skincare, hair care and more from a wide selection of ​international cult beauty brands. Shop now.'
  },
  faq: {
    links: {
      beautyPassSection: 'BEAUTY-PASS-SECTION',
      deliveryOptionsAndShipping: '/faqs/placeholder',
      orders: '/faqs/placeholder',
      reviews: '/faqs/placeholder',
      returnsAndExchange: '/faqs/placeholder',
      payment: '/faqs/placeholder',
      delivery: '/faqs/placeholder'
    }
  },
  terms: {
    companies: 'Sephora Digital SEA Pte Ltd'
  }
}

const bn = {
  country: 'Brunei',
  domain: 'bn.sephora.asia',
  currency: {
    symbol: 'S$', // Needs to be in base currency (S$) for Global-e JS to dynamically convert prices
    step: '10',
    originalSymbol: 'BN$'
  },
  points: {
    conversionRate: '1'
  },
  meta: {
    phoneNumber: '',
    emailCustomerService: '',
    addressCustomerService: '',
    callUsBetweenDaysAndTimings: '',
    description: 'Sephora, where beauty beats. Discover the best in makeup, skincare, hair care and more from a wide selection of ​international cult beauty brands. Shop now.'
  },
  faq: {
    links: {
      beautyPassSection: 'BEAUTY-PASS-SECTION',
      deliveryOptionsAndShipping: '/faqs/placeholder',
      orders: '/faqs/placeholder',
      reviews: '/faqs/placeholder',
      returnsAndExchange: '/faqs/placeholder',
      payment: '/faqs/placeholder',
      delivery: '/faqs/placeholder'
    }
  },
  terms: {
    companies: 'Sephora Digital SEA Pte Ltd'
  }
}

const hk = {
  country: 'Hong Kong SAR',
  domain: 'www.sephora.hk',
  currency: {
    symbol: '$',
    step: '50'
  },
  points: {
    conversionRate: '5'
  },
  meta: {
    phoneNumber: '+852 5808 5441',
    emailCustomerService: 'contact@sephora.hk',
    addressCustomerService: '',
    callUsBetweenDaysAndTimings: '',
    description: 'Sephora, where beauty beats. Discover the best in makeup, skin care, and more from a wide selection of beauty brands. Free shipping for orders above HK$350.'
  },
  app: {
    android: {
      header: 'HK_Header_Android',
      footer: 'HK_Footer_Android'
    },
    ios: {
      header: 'HK_Header_iOS',
      footer: 'HK_Footer_iOS'
    }
  },
  social: {
    facebook: 'sephorahongkong',
    instagram: 'sephorahk',
    youtube: 'channel/UC4ggo1XJx8LUcNI9G_AaXpg',
    hashtag: '#SephoraHK'
  },
  faq: {
    links: {
      beautyPassSection: '360004928432-SEPHORA-BEAUTY-PASS',
      deliveryOptionsAndShipping: '/faqs/360004947571-DELIVERY/360029602952-What-are-your-delivery-options-and-how-much-is-shipping',
      orders: '/faqs/360004947451-Orders',
      reviews: '/faqs/360004947611-OTHERS/360029935951-Why-was-my-review-rejected',
      returnsAndExchange: '/faqs/360004947591-Refunds-Returns-and-Exchange',
      payment: '/faqs/360004955592-Payment',
      delivery: '/faqs/360004947571-Delivery',
      deliveryAttempted: '/faqs/360004947571-Delivery/900001323003-I-was-not-am-not-available-to-receive-my-parcel-upon-delivery'
    }
  },
  signInBanner: {
    heading: 'Sephora Beauty Pass Members Enjoy More!',
    messages: [
      'Unlock exclusive online offers',
      'Access to members-only sales and events',
      'Redeem points to get discounts and gifts'
    ],
    andMoreText: 'And more!',
    signIn: 'Sign In',
    register: 'Sign Up'
  },
  terms: {
    companies: 'Sephora Digital SEA Pte Ltd'
  }
}

const id = {
  country: 'Indonesia',
  domain: 'www.sephora.co.id',
  currency: {
    symbol: 'Rp',
    format: '%s %v',
    decimal: ',',
    thousand: '.',
    precision: 0,
    step: '50000'
  },
  points: {
    conversionRate: '10000'
  },
  meta: {
    phoneNumber: '+62 21 726 3002',
    emailCustomerService: 'contact@sephora.co.id',
    addressCustomerService: '',
    callUsBetweenDaysAndTimings: '',
    description: 'Sephora Indonesia, where beauty beats. Offering the best in makeup, cosmetics, skincare, hair care & mens products. Free shipping for order above Rp200.000!',
    address: 'Jalan Prapanca Raya No.35/36, Kebayoran Baru, Kota Jakarta Selatan, DKI Jakarta 12160',
    postalCode: '12160'
  },
  app: {
    android: {
      header: 'ID_Header_Android',
      footer: 'ID_Footer_Android'
    },
    ios: {
      header: 'ID_Header_iOS',
      footer: 'ID_Footer_iOS'
    }
  },
  social: {
    facebook: 'SephoraID',
    twitter: 'sephoraid',
    instagram: 'sephoraidn',
    youtube: 'channel/UC4ggo1XJx8LUcNI9G_AaXpg',
    snapchat: 'sephorasg',
    hashtag: '#SephoraID'
  },
  faq: {
    links: {
      beautyPassSection: '360003064451-Beauty-Pass',
      deliveryOptionsAndShipping: '/faqs/360002292991-Shipping-and-Delivery/360022555732-What-are-your-delivery-options-and-how-much-is-shipping',
      orders: '/faqs/360003060792-Orders',
      reviews: '/faqs/360003060632-Others/360039039792-Why-was-my-review-rejected',
      returnsAndExchange: '/faqs/360003064431-Returns',
      payment: '/faqs/360003064391-Payment',
      delivery: '/faqs/360002292991-Shipping-and-Delivery',
      deliveryAttempted: '/faqs/360002292991-Shipping-and-Delivery/360022556892-Re-schedule-delivery'
    }
  },
  signInBanner: {
    heading: 'BEAUTY PASS SALE!',
    messages: [
      'SALE NOW ON TILL 31 AUGUST',
      'ENJOY UP TO 20% OFF*',
      'SHOP NOW',
      'T&Cs Apply'
    ],
    andMoreText: 'And more!',
    signIn: 'Sign In',
    register: 'Sign Up'
  },
  terms: {
    companies: 'PT Sephora Indonesia'
  }
}

const jp = {
  country: 'Japan',
  domain: 'jp.sephora.asia',
  currency: {
    symbol: 'S$', // Needs to be in base currency (S$) for Global-e JS to dynamically convert prices
    step: '10',
    originalSymbol: '¥‌'
  },
  points: {
    conversionRate: '80'
  },
  meta: {
    phoneNumber: '',
    emailCustomerService: '',
    addressCustomerService: '',
    callUsBetweenDaysAndTimings: '',
    description: 'Sephora, where beauty beats. Discover the best in makeup, skincare, hair care and more from a wide selection of ​international cult beauty brands. Shop now.'
  },
  faq: {
    links: {
      beautyPassSection: 'BEAUTY-PASS-SECTION',
      deliveryOptionsAndShipping: '/faqs/placeholder',
      orders: '/faqs/placeholder',
      reviews: '/faqs/placeholder',
      returnsAndExchange: '/faqs/placeholder',
      payment: '/faqs/placeholder',
      delivery: '/faqs/placeholder'
    }
  },
  terms: {
    companies: 'Sephora Digital SEA Pte Ltd'
  }
}

const kr = {
  country: 'Korea',
  domain: 'www.sephora.kr',
  meta: {
    phoneNumber: '1670-4234',
    phoneNumber2: '혹은 02-2097-6565',
    emailCustomerService: 'contact@sephora.kr',
    addressCustomerService: '서울 용산구 원효로 90길 11',
    callUsBetweenDaysAndTimings: '평일 9:00am - 6:00pm',
    description: '세포라, 가슴뛰는 뷰티의 세계가 시작되는 곳 다양한 뷰티 브랜드들에서 선택된 최고의 메이크업, 스킨케어, 그리고 그 외의 더 많은 제품들까지 경험해보세요. ₩50,000 이상 주문 시 무료 배송. ',
    address: '서울시 강남구 테헤란로 419',
    postalCode: '06160'
  },
  currency: {
    symbol: '원',
    step: '10000',
    precision: 0,
    format: '%v%s'
  },
  points: {
    conversionRate: '1000'
  },
  social: {
    facebook: 'sephorakorea',
    instagram: 'sephorakr',
    youtube: 'channel/UCCepjK8AbZb2EBSEbTw7ZSA',
    hashtag: '#SephoraKorea',
    kakao: '_KjTgj'
  },
  faq: {
    links: {
      beautyPassSection: '360006959832-세포라-뷰티패스',
      deliveryOptionsAndShipping: '/faqs/360007053071-배송/360034912232-어떤-배송-옵션이-있고-배송비는-얼마인가요-',
      orders: '/faqs/360006959712-주문',
      reviews: '/faqs/360007053091-기타/360034946892-어떻게-하면-좋은-리뷰를-쓸-수-있나요',
      returnsAndExchange: '/faqs/360007053151-반품-및-교환',
      payment: '/faqs/360006959732-결제-및-환불',
      delivery: '/faqs/360007053071-배송',
      deliveryAttempted: '/faqs/360007053071/360035282551'
    }
  },
  terms: {
    companies: '세포라코리아 유한회사'
  }
}

const la = {
  country: 'Laos',
  domain: 'la.sephora.asia',
  currency: {
    symbol: 'S$', // Needs to be in base currency (S$) for Global-e JS to dynamically convert prices
    step: '10',
    originalSymbol: '₭‌'
  },
  points: {
    conversionRate: '7000'
  },
  meta: {
    phoneNumber: '',
    emailCustomerService: '',
    addressCustomerService: '',
    callUsBetweenDaysAndTimings: '',
    description: 'Sephora, where beauty beats. Discover the best in makeup, skincare, hair care and more from a wide selection of ​international cult beauty brands. Shop now.'
  },
  faq: {
    links: {
      beautyPassSection: 'BEAUTY-PASS-SECTION',
      deliveryOptionsAndShipping: '/faqs/placeholder',
      orders: '/faqs/placeholder',
      reviews: '/faqs/placeholder',
      returnsAndExchange: '/faqs/placeholder',
      payment: '/faqs/placeholder',
      delivery: '/faqs/placeholder'
    }
  },
  terms: {
    companies: 'Sephora Digital SEA Pte Ltd'
  }
}

const lk = {
  country: 'Sri Lanka',
  domain: 'lk.sephora.asia',
  currency: {
    symbol: 'S$', // Needs to be in base currency (S$) for Global-e JS to dynamically convert prices
    step: '10',
    originalSymbol: 'SLRs‌'
  },
  points: {
    conversionRate: '150'
  },
  meta: {
    phoneNumber: '',
    emailCustomerService: '',
    addressCustomerService: '',
    callUsBetweenDaysAndTimings: '',
    description: 'Sephora, where beauty beats. Discover the best in makeup, skincare, hair care and more from a wide selection of ​international cult beauty brands. Shop now.'
  },
  faq: {
    links: {
      beautyPassSection: 'BEAUTY-PASS-SECTION',
      deliveryOptionsAndShipping: '/faqs/placeholder',
      orders: '/faqs/placeholder',
      reviews: '/faqs/placeholder',
      returnsAndExchange: '/faqs/placeholder',
      payment: '/faqs/placeholder',
      delivery: '/faqs/placeholder'
    }
  },
  terms: {
    companies: 'Sephora Digital SEA Pte Ltd'
  }
}

const mm = {
  country: 'Myanmar',
  domain: 'mm.sephora.asia',
  currency: {
    symbol: 'S$', // Needs to be in base currency (S$) for Global-e JS to dynamically convert prices
    step: '10',
    originalSymbol: 'MMK‌'
  },
  points: {
    conversionRate: '1100'
  },
  meta: {
    phoneNumber: '',
    emailCustomerService: '',
    addressCustomerService: '',
    callUsBetweenDaysAndTimings: '',
    description: 'Sephora, where beauty beats. Discover the best in makeup, skincare, hair care and more from a wide selection of ​international cult beauty brands. Shop now.'
  },
  faq: {
    links: {
      beautyPassSection: 'BEAUTY-PASS-SECTION',
      deliveryOptionsAndShipping: '/faqs/placeholder',
      orders: '/faqs/placeholder',
      reviews: '/faqs/placeholder',
      returnsAndExchange: '/faqs/placeholder',
      payment: '/faqs/placeholder',
      delivery: '/faqs/placeholder'
    }
  },
  terms: {
    companies: 'Sephora Digital SEA Pte Ltd'
  }
}

const my = {
  country: 'Malaysia',
  domain: 'www.sephora.my',
  currency: {
    symbol: 'RM',
    step: '10'
  },
  points: {
    conversionRate: '3'
  },
  meta: {
    phoneNumber: '+60 16 299 1444',
    emailCustomerService: 'contact@sephora.my',
    addressCustomerService: '',
    callUsBetweenDaysAndTimings: '',
    description: 'Sephora, where beauty beats. Discover the best in makeup, skin care, and more from a wide selection of beauty brands. Free shipping for orders above RM80.'
  },
  app: {
    android: {
      header: 'MY_Header_Android',
      footer: 'MY_Footer_Android'
    },
    ios: {
      header: 'MY_Header_iOS',
      footer: 'MY_Footer_iOS'
    }
  },
  social: {
    facebook: 'SephoraMalaysia',
    instagram: 'sephoramy',
    youtube: 'channel/UC4ggo1XJx8LUcNI9G_AaXpg',
    snapchat: 'sephorasg',
    hashtag: '#SephoraMY'
  },
  faq: {
    links: {
      beautyPassSection: '360004947151-SEPHORA-BEAUTY-PASS',
      deliveryOptionsAndShipping: '/faqs/360004940071-DELIVERY/360029892111-What-are-your-delivery-options-and-how-much-is-shipping',
      orders: '/faqs/360004940031-Orders',
      reviews: '/faqs/360004928152-OTHERS/360029600652-Why-was-my-review-rejected',
      returnsAndExchange: '/faqs/360004928132-Refunds-Returns-and-Exchange',
      payment: '/faqs/360004921852-Payment',
      delivery: '/faqs/360004940071-Delivery',
      deliveryAttempted: '/faqs/360004940071-Delivery/360029565152-I-was-not-am-not-available-to-receive-my-parcel-upon-delivery'
    }
  },
  signInBanner: {
    heading: 'BEAUTY PASS SALE!',
    messages: [
      'SALE NOW ON',
      'GET UP TO 20% OFF*',
      'ONLINE & IN-STORE',
      'T&Cs APPLY'
    ],
    andMoreText: 'And more!',
    signIn: 'Sign In',
    register: 'Sign Up'
  },
  terms: {
    companies: 'Beauty in Motion Sdn Bhd, Sephora Digital SEA Pte Ltd'
  }
}

const nz = {
  country: 'New Zealand',
  domain: 'www.sephora.nz',
  currency: {
    symbol: '$',
    step: '10'
  },
  points: {
    conversionRate: '1'
  },
  meta: {
    phoneNumber: '+64 4 888 0102',
    emailCustomerService: 'contact@sephora.nz',
    addressCustomerService: '',
    callUsBetweenDaysAndTimings: '',
    description: 'Sephora, where beauty beats. Discover the best in makeup, skincare, hair care and more from a wide selection of ​international cult beauty brands. Shop now.'
  },
  app: {
    android: {
      header: 'NZ_Header_Android',
      footer: 'NZ_Footer_Android'
    },
    ios: {
      header: 'NZ_Header_iOS',
      footer: 'NZ_Footer_iOS'
    }
  },
  social: {
    facebook: 'sephoranewzealand',
    instagram: 'sephoranz',
    hashtag: '#SephoraNZ',
    youtube: 'channel/UC4ggo1XJx8LUcNI9G_AaXpg'
  },
  faq: {
    links: {
      beautyPassSection: '360004947311-SEPHORA-BEAUTY-PASS',
      deliveryOptionsAndShipping: '/faqs/360004929072-DELIVERY/360029607352-What-are-your-delivery-options-and-how-much-is-shipping',
      orders: '/faqs/360004947271-Orders',
      reviews: '/faqs/360004947331-OTHERS/360029934251-Why-was-my-review-rejected',
      returnsAndExchange: '/faqs/360004928312-Refunds-Returns-and-Exchange',
      payment: '/faqs/360004947291-Payment',
      delivery: '/faqs/360004929072-Delivery',
      deliveryAttempted: '/faqs/360004929072-Delivery/360029940231-I-was-not-am-not-able-to-receive-my-parcel-upon-delivery'
    }
  },
  signInBanner: {
    heading: 'Sephora Beauty Pass Members Enjoy More!',
    messages: [
      'Unlock exclusive online offers',
      'Access to members-only sales and events',
      'Redeem points to get discounts and gifts'
    ],
    andMoreText: 'And more!',
    signIn: 'Sign In',
    register: 'Sign Up'
  },
  terms: {
    companies: 'Sephora New Zealand Limited, Sephora Digital SEA Pte Ltd'
  }
}

const ph = {
  country: 'Philippines',
  domain: 'www.sephora.ph',
  currency: {
    symbol: '₱',
    step: '500'
  },
  points: {
    conversionRate: '35'
  },
  meta: {
    phoneNumber: '+632 6263336',
    emailCustomerService: 'contact@sephora.ph',
    addressCustomerService: '',
    callUsBetweenDaysAndTimings: '',
    description: 'Sephora, where beauty beats. Discover the best in makeup, skin care, and more from a wide selection of beauty brands. Free shipping for orders above ₱1300.'
  },
  app: {
    android: {
      header: 'PH_Header_Android',
      footer: 'PH_Footer_Android'
    },
    ios: {
      header: 'PH_Header_iOS',
      footer: 'PH_Footer_iOS'
    }
  },
  social: {
    facebook: 'sephoraphilippines',
    twitter: 'sephoraph',
    instagram: 'sephoraph',
    youtube: 'channel/UC4ggo1XJx8LUcNI9G_AaXpg',
    snapchat: 'sephorasg',
    hashtag: '#SephoraPH'
  },
  faq: {
    links: {
      beautyPassSection: '360004928392-SEPHORA-BEAUTY-PASS',
      deliveryOptionsAndShipping: '/faqs/360004928372-DELIVERY/360029934611-What-are-your-delivery-options-and-how-much-is-shipping',
      orders: '/faqs/360004928332-Orders',
      reviews: '/faqs/360004947431-OTHERS/360029602632-Why-was-my-review-rejected',
      returnsAndExchange: '/faqs/360004947411-Refunds-Returns-and-Exchange',
      payment: '/faqs/360004947351-Payment',
      delivery: '/faqs/360004928372-Delivery',
      deliveryAttempted: '/faqs/360004928372-Delivery/900001173763-I-was-not-am-not-available-to-receive-my-parcel-upon-delivery'
    }
  },
  signInBanner: {
    heading: 'BEAUTY PASS SALE',
    messages: [
      'SALE NOW ON TILL 1 SEP',
      'GOLD & BLACK TIER | UP TO 20% OFF*',
      'WHITE TIER | UP TO 15% OFF**',
      'SHOP NOW',
      'T&Cs APPLY'
    ],
    andMoreText: 'And more!',
    signIn: 'Sign In',
    register: 'Sign Up'
  },
  terms: {
    companies: 'Sephora Digital SEA Pte Ltd'
  }
}

const pk = {
  country: 'Pakistan',
  domain: 'pk.sephora.asia',
  currency: {
    symbol: 'S$', // Needs to be in base currency (S$) for Global-e JS to dynamically convert prices
    step: '10',
    originalSymbol: 'Rs‌'
  },
  points: {
    conversionRate: '110'
  },
  meta: {
    phoneNumber: '',
    emailCustomerService: '',
    addressCustomerService: '',
    callUsBetweenDaysAndTimings: '',
    description: 'Sephora, where beauty beats. Discover the best in makeup, skincare, hair care and more from a wide selection of ​international cult beauty brands. Shop now.'
  },
  faq: {
    links: {
      beautyPassSection: 'BEAUTY-PASS-SECTION',
      deliveryOptionsAndShipping: '/faqs/placeholder',
      orders: '/faqs/placeholder',
      reviews: '/faqs/placeholder',
      returnsAndExchange: '/faqs/placeholder',
      payment: '/faqs/placeholder',
      delivery: '/faqs/placeholder'
    }
  },
  terms: {
    companies: 'Sephora Digital SEA Pte Ltd'
  }
}

const sg = {
  country: 'Singapore',
  domain: 'www.sephora.sg',
  currency: {
    symbol: '$',
    step: '10'
  },
  points: {
    conversionRate: '1'
  },
  meta: {
    phoneNumber: '+65 3163 1032',
    emailCustomerService: 'contact@sephora.sg',
    addressCustomerService: '',
    callUsBetweenDaysAndTimings: '',
    description: 'Sephora, where beauty beats. Discover the best in makeup, skin care, and more from a wide selection of beauty brands. Free shipping for orders above S$40.',
    address: '391A Orchard Road, Ngee Ann City Tower A #16-00, Singapore 238873',
    postalCode: '238873'
  },
  app: {
    android: {
      header: 'SG_Header_Android',
      footer: 'SG_Footer_Android'
    },
    ios: {
      header: 'SG_Header_iOS',
      footer: 'SG_Footer_iOS'
    }
  },
  social: {
    facebook: 'sephorasingapore',
    twitter: 'sephorasg',
    instagram: 'sephorasg',
    youtube: 'channel/UC4ggo1XJx8LUcNI9G_AaXpg',
    snapchat: 'sephorasg',
    hashtag: '#SephoraSG'
  },
  faq: {
    links: {
      beautyPassSection: '360004939911-SEPHORA-BEAUTY-PASS',
      deliveryOptionsAndShipping: '/faqs/360004921652-DELIVERY/360043444771-What-are-your-delivery-options-and-how-much-is-shipping',
      orders: '/faqs/360004921532-Orders',
      reviews: '/faqs/360004939951-OTHERS/360029891391-Why-was-my-review-rejected',
      returnsAndExchange: '/faqs/360004921712-Refunds-Returns-and-Exchange',
      payment: '/faqs/360004939871-Payment',
      delivery: '/faqs/360004921652-Delivery',
      deliveryAttempted: '/faqs/360004921652-Delivery/900002386383--I-was-not-am-not-available-to-receive-my-parcel-upon-delivery'
    }
  },
  signInBanner: {
    heading: 'Sephora Beauty Pass Members Enjoy More!',
    messages: [
      'Unlock exclusive online offers',
      'Access to members-only sales and events',
      'Redeem points to get discounts and gifts'
    ],
    andMoreText: 'And more!',
    signIn: 'Sign In',
    register: 'Sign Up'
  },
  terms: {
    companies: 'Sephora Singapore Pte Ltd, Sephora Digital SEA Pte Ltd'
  }
}

const th = {
  country: 'Thailand',
  domain: 'www.sephora.co.th',
  currency: {
    symbol: '฿',
    step: '500'
  },
  points: {
    conversionRate: '25'
  },
  meta: {
    phoneNumber: '+66 2 508 8625',
    emailCustomerService: 'contact@sephora.co.th',
    addressCustomerService: '',
    callUsBetweenDaysAndTimings: '',
    description: 'Sephora TH, where beauty beats. เราได้รวบรวมเมคอัพ สกินแคร์ แฮร์แคร์ และบิวตี้ทิปส์มาไว้เพื่อคุณสาวๆ โดยเฉพาะ พร้อมรับบริการจัดส่งฟรีเมื่อช้อปสินค้าครบ 499 บาท!',
    address: '689 Bhiraj Tower at EmQuartier, 32nd Floor, Suite No. 3202, Sukhumvit Road, North Klongton, Vadhana, Bangkok 10110',
    postalCode: '10110'
  },
  app: {
    android: {
      header: 'TH_Header_Android',
      footer: 'TH_Footer_Android'
    },
    ios: {
      header: 'TH_Header_iOS',
      footer: 'TH_Footer_iOS'
    }
  },
  social: {
    facebook: 'sephorathailand',
    twitter: 'thaisephora',
    instagram: 'thaisephora',
    youtube: 'channel/UCYLhVsJoHNJ8m2bizdP6k8A',
    snapchat: 'thaisephora',
    hashtag: '#SephoraTH',
    line: 'Jv99e75'
  },
  faq: {
    links: {
      beautyPassSection: '360002292751-Beauty-Pass',
      deliveryOptionsAndShipping: '/faqs/360002294892-Shipping-and-Delivery/360020257212-How-much-is-shipping-fee',
      orders: '/faqs/115001585426-Orders',
      reviews: '/faqs/360003063311-Others/360039082552-Why-was-my-review-rejected',
      returnsAndExchange: '/faqs/360003059672-Returns',
      payment: '/faqs/360002294872-Payment',
      delivery: '/faqs/360002294892-Shipping-and-Delivery',
      deliveryAttempted: '/faqs/360002294892-Shipping-and-Delivery/900001265226-I-was-not-am-not-available-to-receive-my-parcel-upon-delivery'
    }
  },
  signInBanner: {
    heading: 'SEPHORA BEAUTY PASS SALE',
    messages: [
      'Sale is On',
      'GOLD Members Get 20% off no min spend*',
      'BLACK Members  up to 20%*',
      'WHITE Members up to 15%**',
      'GET ACCESS '
    ],
    andMoreText: 'And more!',
    signIn: 'Sign In',
    register: 'Sign Up'
  },
  terms: {
    companies: 'Sephora Thailand Co Ltd, Sephora Digital Thailand Ltd'
  }
}

const tw = {
  country: 'Taiwan',
  domain: 'tw.sephora.asia',
  currency: {
    symbol: 'S$', // Needs to be in base currency (S$) for Global-e JS to dynamically convert prices
    step: '10',
    originalSymbol: 'NT$‌'
  },
  points: {
    conversionRate: '20'
  },
  meta: {
    phoneNumber: '',
    emailCustomerService: '',
    addressCustomerService: '',
    callUsBetweenDaysAndTimings: '',
    description: 'Sephora, where beauty beats. Discover the best in makeup, skincare, hair care and more from a wide selection of ​international cult beauty brands. Shop now.'
  },
  faq: {
    links: {
      beautyPassSection: 'BEAUTY-PASS-SECTION',
      deliveryOptionsAndShipping: '/faqs/placeholder',
      orders: '/faqs/placeholder',
      reviews: '/faqs/placeholder',
      returnsAndExchange: '/faqs/placeholder',
      payment: '/faqs/placeholder',
      delivery: '/faqs/placeholder'
    }
  },
  terms: {
    companies: 'Sephora Digital SEA Pte Ltd'
  }
}

const vn = {
  country: 'Vietnam',
  domain: 'vn.sephora.asia',
  currency: {
    symbol: 'S$', // Needs to be in base currency (S$) for Global-e JS to dynamically convert prices
    step: '10',
    originalSymbol: '₫‌'
  },
  points: {
    conversionRate: '17000'
  },
  meta: {
    phoneNumber: '',
    emailCustomerService: '',
    addressCustomerService: '',
    callUsBetweenDaysAndTimings: '',
    description: 'Sephora, where beauty beats. Discover the best in makeup, skincare, hair care and more from a wide selection of ​international cult beauty brands. Shop now.'
  },
  faq: {
    links: {
      beautyPassSection: 'BEAUTY-PASS-SECTION',
      deliveryOptionsAndShipping: '/faqs/placeholder',
      orders: '/faqs/placeholder',
      reviews: '/faqs/placeholder',
      returnsAndExchange: '/faqs/placeholder',
      payment: '/faqs/placeholder',
      delivery: '/faqs/placeholder'
    }
  },
  terms: {
    companies: 'Sephora Digital SEA Pte Ltd'
  }
}

export default {
  au,
  bd,
  bn,
  hk,
  id,
  jp,
  kr,
  la,
  lk,
  mm,
  my,
  nz,
  pk,
  ph,
  sg,
  th,
  tw,
  vn
}
