import Vue from 'vue'

const retailCart = window.localStorage.getItem('retailCart')

const state = {
    cart: JSON.parse(retailCart) || {}
}
const getters = {
    retailCartItem: (state) => (id) => {
        return state.cart[id] || 0
    },

    cartTotal (state) {
        return Object.values(state.cart).reduce((accumulator, itemCount) => {
            return accumulator + itemCount
          }, 0)
    }
}

const mutations = {
    setCart (state, object) {
        state.cart = object
    },
    clearCart (state) {
      state.cart = {}
    }
}

const actions = {
    updateRewardsToBag ({ commit, dispatch }, args) {
        if (args.count <= 0) {
            Vue.delete(state.cart, args.id)
        } else {
            Vue.set(state.cart, args.id, args.count)
        }
        commit('setCart', state.cart)
        dispatch('saveCart')
    },
    saveCart () {
        window.localStorage.setItem('retailCart', JSON.stringify(state.cart))
    },
    removeCart ({ commit }) {
      window.localStorage.removeItem('retailCart')
      commit('clearCart')
    }
}

export default {
    state,
    mutations,
    getters,
    actions
}
