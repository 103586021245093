import ApiBase from './api-base'
import get from 'lodash/get'
import { v2m5mHeaders } from '@/modules/config/api'

class Reward extends ApiBase {
  async fetch () {
    const { data } = await this.client.get('/v2.5/rewards', {
      params: { include: 'variant,tier_exclusive_labels' },
      headers: {
        'X-Cart-Token': get(vueSupport, 'cart.token', '')
      }
    })

    return data
  }

  async fetchRetailRewards (
    storeCode,
    {
      page = 1,
      pageSize = 100,
      rewardIds = []
    } = {}) {
    const url = `/v2.5/retail_stores/${storeCode}/rewards`
    const { data } = await this.client.get(url, {
      headers: v2m5mHeaders,
      params: {
        include: 'variant,tier_exclusive_labels,retail_store',
        'page[number]': page,
        'page[size]': pageSize,
        ...(rewardIds.length > 0 ? { 'filters[ids]': rewardIds } : {})
      }
    })
    return data
  }
}

export default Reward
