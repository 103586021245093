import isEmpty from 'lodash/isEmpty'

const state = {
  overlay: {}
}

const mutations = {
  setOverlay (state, object) {
    state.overlay = object
  }
}

const getters = {
  overlay: (state) => state.overlay
}

const actions = {
  async fetchOverlay ({ commit }, path) {
    const url = `/api/v2.5/overlays/latest?path=${path}`

    try {
      const response = await window.axios.get(url)

      if (!isEmpty(response?.data?.data)) {
        commit('setOverlay', response?.data?.data)
      }
    } catch {}
  }
}

export default {
  state,
  mutations,
  getters,
  actions
}
