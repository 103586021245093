import i18n from '../i18n'

const SORT_VALUES = {
  RELEVANCE: 'relevance',
  RECOMMENDED: 'recommended',
  BESTSELLING: 'sales',
  MOST_RECENT: 'published_at',
  TOP_RATED: 'rating',
  PRICE_LOW_TO_HIGH: 'price',
  PRICE_HIGH_TO_LOW: '-price'
}

const productsIndexPageSizes = [30, 60, 120]

const longTailKeywordProductsPageSize = 10

const productsIndexDefaultPageSize = productsIndexPageSizes[0]

const productsIndexSortOptions = (entityType, landingPageLayout) => {
  return [
    ...(entityType === 'search' ? [{ value: SORT_VALUES.RELEVANCE, text: i18n.t('common.sortRelevance') }] : []),
    ...(entityType === 'landing-pages' && landingPageLayout === 'variant_selection'
      ? [{ value: SORT_VALUES.RECOMMENDED, text: i18n.t('common.sortBestselling') }]
      : [{ value: SORT_VALUES.BESTSELLING, text: i18n.t('common.sortBestselling') }]
    ),
    { value: SORT_VALUES.MOST_RECENT, text: i18n.t('common.sortMostRecent') },
    { value: SORT_VALUES.TOP_RATED, text: i18n.t('common.sortTopRated') },
    { value: SORT_VALUES.PRICE_LOW_TO_HIGH, text: i18n.t('common.sortPriceLowToHigh') },
    { value: SORT_VALUES.PRICE_HIGH_TO_LOW, text: i18n.t('common.sortPriceHighToLow') }
  ]
}

const productsIndexDefaultSort = (entityType, landingPageLayout) => {
  if (entityType === 'search') {
    return SORT_VALUES.RELEVANCE
  } else if (entityType === 'landing-pages' && landingPageLayout === 'variant_selection') {
    return SORT_VALUES.RECOMMENDED
  } else {
    return SORT_VALUES.BESTSELLING
  }
}

export {
  productsIndexPageSizes,
  longTailKeywordProductsPageSize,
  productsIndexDefaultPageSize,
  productsIndexSortOptions,
  productsIndexDefaultSort
}
