import ApiBase from './api-base'
import * as DataFormatter from '@/components/checkout-v3/api/data-formatter'
import splitio from '@/modules/services/split-io'

class Product extends ApiBase {
  async fetchProduct (id, { variantId = null } = {}) {
    const version = await isEarlyAccessFlagOn() ? '2.6' : '2.5'
    const url = `/v${version}/products/${id}`

    const { data } = await this.client.get(url, {
      params: {
        ...(variantId ? { v: variantId } : {}),
        include: 'variants.filter_values,variants.ingredient_preference,featured_ad.virtual_bundle.bundle_elements,product_articles,filter_types'
      }
    })

    return data
  }

  async fetchLegalInfo (id) {
    const url = `/v2.1/product_legal_infos/${id}`
    const { data } = await this.client.get(url, {
      params: {
        include: ''
      }
    })
    return data
  }

  async fetchProductReviews (
    id,
    { page = 1, sort = 'recent', variantIds = [], excludeUSReviews = false, rating = null, country = null } = {}
  ) {
    const url = `/v2.4/products/${id}/reviews`
    const { data } = await this.client.get(url, {
      params: {
        'page[number]': page,
        'page[size]': 10,
        sort,
        ...(country ? { 'filter[country]': country } : {}),
        ...(variantIds.length > 0 ? { 'filter[variants]': variantIds } : {}),
        ...(excludeUSReviews ? { 'filter[native]': true } : {}),
        ...(rating ? { 'filter[rating]': rating } : {})
      }
    })

    return data
  }

  async fetchProducts ({
    entityType = '',
    entitySlug = '',
    pageSize = 30,
    pageNumber = 1,
    sort = ''
  }) {
    const url = '/v2.3/products'

    const { data } = await this.client.get(url, {
      params: {
        [`filter[${entityType}]`]: entitySlug,
        'page[size]': pageSize,
        'page[number]': pageNumber,
        sort,
        include: 'variants,brand,featured_ad'
      }
    })

    return data
  }

  async fetchLongTailKeyword (keywordSlug) {
    const url = `/v2.3/long_tail_keywords/${keywordSlug}`
    const params = { include: 'related_keywords' }
    const { data } = await this.client.get(url, { params: params })
    return data
  }

  async fetchLongTailKeywordProducts (keyword, pageSize, categoryId = null) {
    const url = '/v2.3/products'
    const params = {
      query: keyword,
      'page[size]': pageSize,
      'where[without_oos]': true,
      include: 'featured_variant,featured_review'
    }
    if (categoryId) {
      params['where[category]'] = categoryId
    }
    const { data } = await this.client.get(url, { params: params })

    return data
  }

  async fetchBundleElementProducts (id, {
    pageSize = 8,
    pageNumber = 1,
    variantId = null
  }) {
    const url = `/v2.5/bundle_elements/${id}/products`

    const { data } = await this.client.get(url, {
      params: {
        ...(variantId ? { variant_id: variantId } : {}),
        'page[size]': pageSize,
        'page[number]': pageNumber,
        include: 'variants,brand,featured_ad'
      }
    })

    return data
  }

  async fetchInstallments (id) {
    const url = `/v2.5/bnpl/products/${id}/variants-installment-details`
    const { data } = await this.client.get(url)

    return data
  }

  async fetchDiscountCoupons (id) {
    const url = `/v2/products/${id}/offers`
    const { data } = await this.client.get(url)

    return data
  }

  async fetchRetailStores (id, params) {
    const url = `/v2.5/products/${id}/retail_stores`
    const response = await this.client.get(url, { params })
    const formattedData = DataFormatter.format(response.data)

    return { data: formattedData }
  }
}

export function isEarlyAccessFlagOn () {
  return new Promise((resolve) => {
    const treatment = splitio.getTreatment('enable_early_access')
    resolve(treatment)
  })
}

export default Product
