const development = {
  facebook: {
    au: {
      appId: '401647733717867'
    },
    bd: {
      appId: '401647733717867'
    },
    bn: {
      appId: '401647733717867'
    },
    hk: {
      appId: '401647733717867'
    },
    id: {
      appId: '401647733717867'
    },
    jp: {
      appId: '401647733717867'
    },
    kr: {
      appId: '720111815349864'
    },
    la: {
      appId: '401647733717867'
    },
    lk: {
      appId: '401647733717867'
    },
    mm: {
      appId: '401647733717867'
    },
    my: {
      appId: '401647733717867'
    },
    nz: {
      appId: '401647733717867'
    },
    ph: {
      appId: '401647733717867'
    },
    pk: {
      appId: '401647733717867'
    },
    sg: {
      appId: '401647733717867'
    },
    th: {
      appId: '401647733717867'
    },
    tw: {
      appId: '401647733717867'
    },
    vn: {
      appId: '401647733717867'
    }
  }
}
export default development
