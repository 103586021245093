import get from 'lodash/get'
import camelCase from 'lodash/camelCase'

class UserAdapter {
  adaptData ({ data = {} }) {
    const attributes = get(data, 'attributes', {})

    return {
      cardNumber: attributes['card-number'],
      dob: attributes.dob || attributes['date-of-birth'],
      email: attributes.email,
      firstName: attributes['first-name'],
      lastName: attributes['last-name'],
      countryCallingCode: attributes['country-calling-code'],
      phoneNumber: attributes['mobile-phone']
    }
  }

  adaptError (error, options = {}) {
    const errors = get(error, 'data.errors', [])
    const errorMeta = get(error, 'data.meta', {})

    if (options.withMeta && errorMeta?.error_type === 'blocked') {
      return errors.filter(e => !e.meta).map(e => {
        return {
          // supports error serializers from ActiveModel and v2.5
          source: camelCase(get(e, 'attribute') || get(e, 'source.pointer', []).split('/').pop()),
          detail: e.detail,
          meta: errorMeta
        }
      })
    } else if (options.full) {
      return [{ detail: get(errors, '[0].meta.message', []).join(', ') }]
    }

    return errors.filter(e => !e.meta).map(e => {
      return {
        // supports error serializers from ActiveModel and v2.5
        source: camelCase(get(e, 'attribute') || get(e, 'source.pointer', []).split('/').pop()),
        detail: e.detail
      }
    })
  }
}

export default UserAdapter
