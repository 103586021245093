import { createPersistedQueryLink } from 'apollo-link-persisted-queries'
import { createHttpLink } from 'apollo-link-http'
import { ApolloLink } from 'apollo-link'
import { ApolloClient } from 'apollo-client'

// Cache usage recipes
// https://github.com/apollographql/apollo-client/tree/master/packages/apollo-cache-inmemory#recipes
import { InMemoryCache } from 'apollo-cache-inmemory'
import { fullUrl } from '@/modules/services/utilities'

import { routes } from '@/modules/config/api'

let graphQLFullPath
const GRAPHQL_PATH = '/graphql'

if (routes.graphQLBaseUrl) {
  graphQLFullPath = `${routes.graphQLBaseUrl}${GRAPHQL_PATH}`
} else {
  graphQLFullPath = fullUrl(GRAPHQL_PATH)
}

const link = ApolloLink.from([
  createPersistedQueryLink({ useGETForHashedQueries: true }),
  createHttpLink({ uri: graphQLFullPath }) // HTTP connection to the API
])

// Cache implementation
const cache = new InMemoryCache()

// Create the apollo client
const graphqlClient = new ApolloClient({
  link,
  cache
})

export {
  graphqlClient
}
