export default class AlgoliaCategoriesTransformer {
  constructor (categories) {
    this.categories = categories
  }

  get transformedData () {
    const languageCode = I18n.acceptLanguage.split('-')[0]
    return this.categories.map((category) => {
      const parent = category.parent === null ? null : {
        id: category.parent.id.toString(),
        attributes: {
          label: category.parent.label[languageCode],
          'slug-url': category.parent['slug-url']
        }
      }

      return {
        id: category.objectID,
        type: 'categories',
        attributes: {
          label: category.label[languageCode],
          'slug-url': category['slug-url'],
          parent
        }
      }
    })
  }
}
