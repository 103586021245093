import get from 'lodash/get'
import ApiBase from '@/modules/api/api-base'

const state = {
  categories: [],
  categoriesSelected: [],
  categoriesLoading: false,

  // index filters
  filterCategorySlug: '',
  filterCategoryTrail: []
}

const getters = {
  childCategoriesFor: (state, getters) => (parent) => {
    const parentId = parseInt(parent.id)

    return state.categories.filter(category => category.attributes['parent-id'] === parentId)
  },

  availableChildCategoriesFor: (state, getters, rootState) => (parent) => {
    const parentId = parseInt(parent.id)
    const categories = (rootState.product.entityType === 'categories') ? state.categories : rootState.product.availableCategoryFilters

    return categories.filter(category => category.attributes['parent-id'] === parentId)
  },

  childCategoriesSizeFor: (state) => (parent) => {
    const parentId = parseInt(parent.id)

    return state.categories.filter(category => category.attributes['parent-id'] === parentId).length
  },

  topLevelCategories: (state) => {
    return state.categories.filter(category => category.attributes['parent-id'] === null)
  },

  showSubcategoriesFor: (state, getters) => (parent) => {
    if (state.categoriesSelected.length === 0) return false

    for (const category of state.categoriesSelected) {
      const currentCategoryAndParentIds = getters.parentIdsForCategory(category)
      if (currentCategoryAndParentIds.indexOf(parent.id) !== -1) {
        return true
      }
    }
    return false
  },

  parentIdsForCategory: (state, getters) => (child) => {
    const parentId = get(child, 'attributes["parent-id"]')
    if (!parentId) { return child.id }
    const parent = state.categories.find(category => parseInt(category.id) === parentId)
    return `${child.id}/${getters.parentIdsForCategory(parent)}`
  },

  parentCategoryFor: (state, getters) => (child) => {
    if (child.attributes['parent-id'] === null) return []

    const parent = state.categories.find(category => parseInt(category.id) === child.attributes['parent-id'])
    return parent
  },

  ancestorCategoryForId: (state, getters) => (childId) => {
    const parentId = state.categories.find(category => category.id === childId.toString()).attributes['parent-id']
    if (!parentId) return childId.toString()
    return getters.ancestorCategoryForId(parentId)
  },

  findCategoryById: (state) => (id) => {
    return state.categories.find(category => category.id === id.toString())
  },

  findCategory: (state) => (category) => {
    if (category.attributes) {
      return category
    } else {
      return state.categories.find(c => c.id === category.id)
    }
  },

  filterCategory (state) {
    return state.categories.find(category => category.attributes['slug-url'] === state.filterCategorySlug) || {}
  }
}

const mutations = {
  setCategories (state, array) {
    state.categories = array
  },

  setCategoriesSelected (state, array) {
    state.categoriesSelected = array
  },

  setCategoriesLoading (state, bool) {
    state.categoriesLoading = bool
  },

  setFilterCategorySlug (state, categorySlug) {
    if (Array.isArray(categorySlug)) {
      categorySlug = categorySlug.join('/')
    }
    state.filterCategorySlug = categorySlug
  },

  setFilterCategoryTrail (state, args) {
    if (args.pop) {
      state.filterCategoryTrail.splice(-1)
    } else if (args.push) {
      state.filterCategoryTrail.push(args.push)
    } else if (args.clear) {
      state.filterCategoryTrail = []
    }
  }
}

const actions = {
  fetchCategories ({ commit }) {
    const api = new ApiBase()
    const url = '/v2.5/categories'

    commit('setCategoriesLoading', true)

    return api.client.get(url).then((response) => {
      commit('setCategories', response.data.data)
      commit('setCategoriesLoading', false)
    }).catch((error) => {
      // Just show and error. Don't fail silently.
      console.warn(error)
    })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
