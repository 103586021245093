const authenticationSteps = { // capitalize to match component's name
  VALIDATE: 'AuthValidateEmail',
  VALIDATE_GUEST: 'AuthValidateGuestEmail',
  SELECT_SIGN_IN_METHOD: 'AuthSelectSignInMethod',
  SIGN_IN: 'AuthSignIn',
  AUTHENTICATION_BLOCKED: 'ForterBlockedNotification',
  OKTA_SIGN_IN: 'Loader',
  REGISTER_RETAIL: 'AuthRegisterRetail',
  REGISTER_NEW: 'AuthRegisterNew',
  REGISTRATION_FORM: 'AuthRegisterBaseForm',
  REGISTER_SOCIAL: 'AuthRegisterSocial',
  SIGNED_IN: 'SignedIn',
  SECURITY_POLICY: 'AuthSecurityPolicy',
  RESET_PASSWORD: 'ResetRequest',
  VERIFY_PREFERRED_LOGIN_METHOD: 'AuthVerifyPreferredLoginMethod',
  VERIFY_PREFERRED_PASSWORD_LOGIN: 'AuthSignIn',
  VERIFICATION_EMAIL_SENT: 'AuthVerifyEmailSent',
  MARKETPLACE_SIGN_IN: 'MarketplaceSignIn',
  AUTH_FRAUD_CHECK_OTP: 'AuthFraudCheckOtp',
  SIGN_IN_OTP: 'AuthSignInOtp',
  PASSWORD_SIGN_IN: 'AuthPasswordSignIn',
  FINAL: null // on this step checkout main component will show its' own final component
}

const signUpTypes = {
  default: 'default',
  retail: 'retail',
  acquisition: 'acquisition',
  lazada: 'lazada'
}

const passwordValidationTypes = {
  resetPassword: 'reset_password',
  signUp: 'sign_up'
}

const refreshTokenPath = `/auth/v1/token`

export {
  authenticationSteps,
  signUpTypes,
  passwordValidationTypes,
  refreshTokenPath
}
