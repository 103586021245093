import Vue from 'vue'
import VueI18n from 'vue-i18n'

import en from './en.json'
import th from './th.json'
import id from './id.json'
import zh from './zh.json'
import ko from './ko.json'
import jaJP from './ja-JP.json'
import viVN from './vi-VN.json'
import zhTW from './zh-TW.json'
import locales from './locales'

Vue.use(VueI18n)

const messages = {
  ...locales,
  lng_en: en,
  lng_id: id,
  lng_ko: ko,
  lng_th: th,
  lng_zh: zh,
  'ja-JP': jaJP,
  'vi-VN': viVN,
  'zh-TW': zhTW
}

export default new VueI18n({
  locale: window.I18n.language,
  fallbackLocale: {
    'zh-TW': ['lng_zh'],
    default: ['lng_en']
  },
  silentTranslationWarn: true,
  preserveDirectiveContent: true,
  messages
})
