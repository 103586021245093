import ApiBase from './api-base'
import { v2m5mHeaders } from '@/modules/config/api'

class Feedback extends ApiBase {
  async pushFeedbackToSheet ({ storeCode, sheetData }) {
    const url = `/v2.5/retail_stores/${storeCode}/feedbacks`
    const { data } = await this.client.post(url, {
      ...sheetData
    }, {
      headers: {
        ...v2m5mHeaders
      }
    })
    return data
  }
}

export default Feedback
