import { Validator } from 'vee-validate'

const importLocalizationFile = languageCode => {
  return import(`./${languageCode}`)
}

export const extractLanguageCode = () => {
  if (I18n.language.includes('lng_')) {
    return I18n.language.replace('lng_', '')
  } else {
    return I18n.language.split('-')[0]
  }
}

export const setVValidateLocalization = async () => {
  const languageCode = extractLanguageCode()
  const language = await importer.importLocalizationFile(languageCode)
  Validator.updateDictionary(language.default)
  Validator.setLocale(languageCode)
}

const importer = {
  importLocalizationFile,
  extractLanguageCode,
  setVValidateLocalization
}

export default importer
