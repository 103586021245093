import ApiBase from './api-base'
import { setCartHeaders } from './utils'

class Sample extends ApiBase {
  constructor () {
    super()

    this.url = '/v2.5/cart/samples'
    this.setHeaders()
  }

  setHeaders ({ token } = {}) {
    this.headers = setCartHeaders(token)
  }

  async fetchSamples () {
    const { data } = await this.client.get(this.url, {
      params: {
        include: 'variants'
      },
      headers: this.headers
    })

    return data
  }

  async createSample ({ sampleId }) {
    const { data } = await this.client.post(this.url, {
      cart: {
        sample_id: sampleId
      }
    },
    {
      headers: this.headers
    })

    return data
  }
}

export default Sample
