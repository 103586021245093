export default class AlgoliaBrandsTransformer {
  constructor (brands) {
    this.brands = brands
  }

  get transformedData () {
    const languageCode = I18n.acceptLanguage.split('-')[0]

    return this.brands.map((brand) => {
      return {
        id: brand.objectID,
        type: 'brands',
        attributes: {
          name: brand.name[languageCode],
          'slug-url': brand['slug-url']
        }
      }
    })
  }
}
