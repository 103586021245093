import clone from 'lodash/clone'
import isEqual from 'lodash/isEqual'
import get from 'lodash/get'
import set from 'lodash/set'
import Cart from '@/modules/api/cart'
import Sample from '@/modules/api/sample'
import { gwpModalUtil } from '@/mixins/cartUtil.js'
import { setFlashMessageCookie } from '@/modules/services/cookies'
import { notifyBugsnag } from '@/modules/services/bugsnag'

let headersConfig = {}
if (typeof vueSupport !== 'undefined') {
  headersConfig = {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-Cart-Token': get(vueSupport, 'cart.token')
    }
  }
}

const cartApi = new Cart()
const sampleApi = new Sample()

export const CART_TYPES = {
  regular: 'regular',
  express: 'express'
}

export const SHIPPING_METHOD_OFFER = 'shipping_method'
export const PROGRESS_OFFER = 'progress'
export const CART_MESSAGE_OFFER = 'cart_message'
export const COUNTDOWN_TIMER_OFFER = 'countdown_timer'
export const LOYALTY_TIER = 'loyalty_tier'

const state = {
  multipleGwp: '',
  multipleGwpVariants: [],
  selectedVariants: [], // Selected Variant IDs are already submitted
  selectingVariants: [], // Variant IDs are selecting in the modal
  lineItems: [],
  cart: null,
  cartProducts: [],
  cartVariants: [],
  cartSamples: [],
  cartLoading: false,
  showCartDropdown: false,
  samples: [],
  promoCodes: [],
  selectedPromoCode: null,
  applyingPromoCode: false
}

const getters = {
  cartId: state => get(state, 'cart.id', null),
  cartMessages: state => get(state, 'cart.attributes.exclusive-offers', []).filter(offer => offer.source === CART_MESSAGE_OFFER),
  cartToken: state => get(state, 'cart.attributes.token', null) || get(vueSupport, 'cart.token', null),
  countdownTimers: state => get(state, 'cart.attributes.exclusive-offers', []).filter(offer => offer.source === COUNTDOWN_TIMER_OFFER),
  exclusiveOffers: state => get(state, 'cart.attributes.exclusive-offers', []).filter(offer => [SHIPPING_METHOD_OFFER, PROGRESS_OFFER, LOYALTY_TIER].includes(offer.source)),
  tierUpgradeOffer: state => get(state, 'cart.attributes.exclusive-offers', []).find(offer => offer.source === LOYALTY_TIER) || {},
  findLineItemByVariantId: (state) => (variantId) => {
    return state.lineItems.find(item => item.attributes['variant-id'] === parseInt(variantId))
  },
  isVariantSelected: (state) => (variantId) => {
    return state.selectingVariants.includes(parseInt(variantId))
  },
  lineItemsCount: state => get(state, 'cart.attributes.line-items-count', state.lineItems.length),
  maxFreeSamples: state => get(state, 'cart.attributes.max-free-samples', 0)
}

const mutations = {
  setMultipleGwp (state, object) {
    state.multipleGwp = object
  },

  setMultipleGwpVariants (state, array) {
    state.multipleGwpVariants = array
  },

  setSelectedVariants (state, array) {
    state.selectedVariants = array
  },

  setSelectingVariants (state, array) {
    state.selectingVariants = array
  },

  setLineItems (state, array) {
    state.lineItems = array
  },

  setCart (state, object) {
    state.cart = object
  },

  setCartProducts (state, array) {
    state.cartProducts = array
  },

  setCartVariants (state, array) {
    state.cartVariants = array
  },

  setCartSamples (state, array) {
    state.cartSamples = array
  },

  setCartLoading (state, bool) {
    state.cartLoading = bool
  },

  setShowCartDropdown (state, bool) {
    state.showCartDropdown = bool
  },

  setSamples (state, array) {
    state.samples = array
  },

  setPromoCodes (state, array) {
    state.promoCodes = array
  },

  setSelectedPromoCode (state, value) {
    state.selectedPromoCode = value
  },

  setApplyingPromoCode (state, bool) {
    state.applyingPromoCode = bool
  },

  setExclusiveOffers (state, exclusiveOffers) {
    state.cart ||= {}
    set(state.cart, 'attributes.exclusive-offers', exclusiveOffers)
  }
}

const actions = {
  fetchMultipleGwp ({ commit, dispatch }, { gwpId }) {
    dispatch('resetGwpState')
    if (gwpId) {
      const url = `/api/v2.4/multiple_gwps/${gwpId}?include=variants`
      return window.axios.get(url, headersConfig).then((response) => {
        commit('setMultipleGwp', response.data.data)
        commit('setMultipleGwpVariants', response.data.included.filter(included => included.type === 'variants'))

        // directly set array2 = array1 will cause reference behavior in Javascript
        // ex: array1 = [1, 2, 3], array1[1] = 4, console.log(array2[1]) ==> 4
        // clone will help to prevent it
        commit('setSelectedVariants', clone(response.data.data.attributes['gwps-selected']))
        commit('setSelectingVariants', clone(response.data.data.attributes['gwps-selected']))
      })
    }
  },

  fetchAvailablePromoCodes ({ commit, dispatch }) {
    const url = `/api/v2/cart/discount_coupons`
    return window.axios.get(url, headersConfig).then((response) => {
      commit('setPromoCodes', response.data.data)
    }).catch((error) => {
      console.warn(error)
    })
  },

  applyDiscountCoupon ({ state }) {
    const url = `/api/cart/update_coupon`
    return window.axios.put(url, {
      cart: {
        coupon_code: state.selectedPromoCode
      }
    }, headersConfig).then((response) => {
      const gwpId = response.data.gwp_id
      if (gwpId) {
        luxola.bindVueEvents('enableGwpEvent', gwpId)
      }

      return gwpId
    }).catch((error) => {
      luxola.updateFlash(error)
    }).then((gwpId) => {
      if (gwpId == null) {
        window.location.href = '/cart'
      }
    })
  },

  submitChoices ({ state, dispatch, commit }) {
    if (state.selectingVariants.length === 0) {
      gwpModalUtil.methods.showNoSelectionModal()
      if (state.selectedVariants.length === 0) {
        gwpModalUtil.methods.recordSubmittingGwpModalGtm()
      }
    } else if (isEqual(state.selectedVariants.sort(), state.selectingVariants.sort())) {
      gwpModalUtil.methods.hideGwpModal()
    } else {
      commit('setSelectedVariants', clone(state.selectingVariants))
      dispatch('submitSelection')
      gwpModalUtil.methods.recordSubmittingGwpModalGtm()
    }
  },

  submitSelection ({ state, dispatch }) {
    gwpModalUtil.methods.hideGwpModal()
    const url = `/api/v2.4/cart/discount_coupon`
    return window.axios.post(url, {
      cart: {
        coupon_code: state.multipleGwp.attributes.code,
        multiple_gwps_selection: state.selectedVariants
      }
    }, headersConfig).then((response) => {
    }).catch((error) => {
      dispatch('resetGwpState')
      luxola.updateFlash(error.response.data.errors[0].meta.message)
    }).then(() => {
      window.location.href = '/cart'
    })
  },

  removePromoCode () {
    const url = `/api/v2.4/cart/discount_coupon`
    return window.axios.delete(url, headersConfig).then((response) => {
    }).catch((error) => {
      luxola.updateFlash(error.response.data.errors[0].meta.message)
    }).then(() => {
      window.location.href = '/cart'
    })
  },

  resetGwpState ({ commit }) {
    commit('setMultipleGwp', '')
    commit('setMultipleGwpVariants', [])
    commit('setSelectedVariants', [])
    commit('setSelectingVariants', [])
  },

  addVariant ({ state, commit }, { id, inventory }) {
    if (state.selectingVariants.length < state.multipleGwp.attributes['max-selectable'] && inventory > 0) {
      const variantIds = clone(state.selectingVariants)
      variantIds.push(parseInt(id))
      commit('setSelectingVariants', variantIds)
    }
  },

  removeVariant ({ state, commit }, { id }) {
    const i = state.selectingVariants.indexOf(parseInt(id))
    const variantIds = clone(state.selectingVariants)
    variantIds.splice(i, 1)
    commit('setSelectingVariants', variantIds)
  },

  async getLineItemsCount ({ state, commit }, { resetCart } = {}) {
    commit('setCartLoading', true)

    // Rely on the session cookie to get the correct cart details when user is authenticated
    // This is required because to update the cart token header setup inside the cartApi
    if (resetCart) {
      cartApi.setHeaders({ token: false })
    } else {
      cartApi.setHeaders({ token: get(state, 'cart.attributes.token', false) })
    }

    try {
      const response = await cartApi.fetchLineItemsCount()
      // Just cart token and line items count
      cartApi.setHeaders({ token: response.data.attributes.token })
      commit('setCart', response.data)
    } catch (error) {}
    commit('setCartLoading', false)
  },

  async getLineItems ({ commit, dispatch, getters }) {
    commit('setCartLoading', true)

    const response = await cartApi.fetch(getters.cartToken)
    await dispatch('assignCart', response)

    commit('setCartLoading', false)
  },

  async getLineItemsForCartPopup ({ commit, dispatch, getters }) {
    commit('setCartLoading', true)
    cartApi.setHeaders({ token: getters.cartToken })

    try {
      const response = await cartApi.fetchCartPopup()
      dispatch('assignCartPopup', response)
    } catch (error) {
      notifyBugsnag(error)
    }
    commit('setCartLoading', false)
  },

  async addToBag ({ commit, dispatch }, args) {
    commit('setCartLoading', true)
    const addToBagMethod = vueSupport.featureFlags.enableV2m5AddToBag ? 'addLineItemToBag' : 'createLineItem'
    const { success, data } = await cartApi[addToBagMethod]({
      variantId: parseInt(args.variantId),
      quantity: args.quantity
    })
    if (success) {
      dispatch('assignCart', data)
      commit('setShowCartDropdown', true)
    }
    if (data.meta) {
      if (data.meta.force) {
        commit('setNotification', {
          msg: data.meta.message,
          type: success ? 'warning' : 'error'
        })
      } else {
        setFlashMessageCookie(data.meta.message)
      }
    } else {
      // reset flash message when user add to bag again.
      commit('setNotification', {})
    }

    // Sends ATC event to DY
    // eslint-disable-next-line no-undef
    dyUtil.trackAtcEvent({ ...args, included: data.included })
    commit('setCartLoading', false)
  },

  async addSample ({ dispatch }, args) {
    try {
      await sampleApi.createSample({
        sampleId: parseInt(args.sampleId)
      })
    } catch (error) {
      luxola.updateFlash(error)
    }
  },

  async fetchSamples ({ commit }, args) {
    try {
      const { data } = await sampleApi.fetchSamples()
      commit('setSamples', data)
    } catch (error) {
      luxola.updateFlash(error)
    }
  },

  async addToBagMultiple ({ state, commit, dispatch, getters }, args) {
    commit('setCartLoading', true)

    try {
      const response = await cartApi.createMultipleLineItems({
        variantIds: args.variantIds
      })
      dispatch('assignCart', response)
      commit('setShowCartDropdown', true)
    } catch (error) {}
    commit('setCartLoading', false)
  },

  async removeLineItem ({ commit, dispatch }, args) {
    commit('setCartLoading', true)

    try {
      const response = await cartApi.removeLineItem(args)
      dispatch('assignCart', response)
    } catch (error) {}
    commit('setCartLoading', false)
  },

  assignCart ({ commit }, args) {
    commit('setCart', args.data)
    if (vueSupport.featureFlags.enableV2m5AddToBag && args.data.attributes['line-items'] !== undefined) {
      commit('setLineItems', args.data.attributes['line-items'])
      commit('setCartProducts', args.data.attributes['line-items'])
      commit('setCartVariants', args.data.attributes['line-items'])
    } else {
      commit('setLineItems', (args.included || []).filter(item => item.type === 'line-items'))
      commit('setCartProducts', (args.included || []).filter(item => item.type === 'products'))
      commit('setCartVariants', (args.included || []).filter(item => item.type === 'variants'))
      commit('setCartSamples', (args.included || []).filter(item => item.type === 'samples'))
    }
  },

  assignCartPopup ({ commit }, args) {
    commit('setCart', args.data)
    const lineItems = args.data.attributes['line-items']
    commit('setLineItems', args.data.attributes['line-items'])
    commit('setCartProducts', (lineItems).filter(item => item.type === 'product' || item.type === 'gwp'))
    commit('setCartSamples', (lineItems).filter(item => item.type === 'free_sample'))
  },

  async addRedeemableToBag ({ commit, dispatch }, args) {
    commit('setCartLoading', true)

    const response = await cartApi.createRedeemable({
      redeemableId: parseInt(args.redeemableId)
    })

    dispatch('assignCart', response)
    commit('setShowCartDropdown', true)
    commit('setCartLoading', false)
  }

}

export default {
  state,
  mutations,
  actions,
  getters
}
