import Vue from 'vue'
import store from './store'
import router from './router'
import i18n from './modules/i18n'

import VueLazyload from 'vue-lazyload'
import VueScrollTo from 'vue-scrollto'
import VueHead from 'vue-head'
import VeeValidate from 'vee-validate'
import bugsnagVue from '@bugsnag/plugin-vue'
import VueObserveVisibility from 'vue-observe-visibility'

import App from './components/app.vue'
import { setupAxios } from '@/modules/config/axios'
import { bugsnagClient } from './modules/services/bugsnag'
import { setVValidateLocalization } from '@/modules/vee-validate'
import splitio from '@/modules/services/split-io'

Vue.use(VueScrollTo, {
  easing: 'ease-in-out'
})
Vue.use(VueLazyload, {
  preLoad: 1.3,
  lazyComponent: true
})
Vue.use(VueHead, {
  separator: '|',
  complement: `${i18n.t('meta.company')} ${i18n.t(
    'country',
    I18n.siteCountryLs
  )}`
})

Vue.use(VeeValidate)
Vue.use(VueObserveVisibility)
Vue.prototype.$featureFlag = splitio

// localize dictionary of vee validate errors
setVValidateLocalization()
setupAxios()

const vue = new Vue({
  el: '#app-vue',
  store,
  router,
  i18n,
  components: {
    App
  }
})

bugsnagClient.use(bugsnagVue, Vue)

window.vue = vue
