import Vue from 'vue'
import Router from 'vue-router'
import { checkValidUser } from './util/authentication'
import { ssrRedirect } from './util/ssr_redirection'
import { OBJECT_STORES } from '@/components/checkout-v3/lib/constants'

Vue.use(Router)

// Lazy Loading of components
const HomeIndex = () =>
  import(
    /* webpackChunkName: 'vue-home-index' */ '@/components/home/index/main.vue'
  )

const VirtualBundlesShow = () =>
  import(
    /* webpackChunkName: 'vue-virtual-bundles-show' */ '@/components/virtual-bundles/main.vue'
  )

const LandingPagesShow = () =>
  import(
    /* webpackChunkName: 'vue-landing-pages-show' */ '@/components/landing-pages/main.vue'
  )

const ProductsIndex = () =>
  import(
    /* webpackChunkName: 'vue-products-index' */ '@/components/products/index/main.vue'
  )
const ProductsShow = () => {
  return import(
    /* webpackChunkName: 'vue-products-show' */ '@/components/products/product-show.vue'
  )
}

const ProductsReviews = () =>
  import(
    /* webpackChunkName: 'vue-products-reviews' */ '@/components/products/reviews/main.vue'
  )
const BrandsIndex = () =>
  import(
    /* webpackChunkName: 'vue-brands-index' */ '@/components/brands/index/main.vue'
  )

const BookingServiceMain = () =>
  import(
    /* webpackChunkName: 'vue-booking-service' */ '@/components/booking-service/main.vue'
  )

const BeautyFeedIndex = () =>
  import(
    /* webpackChunkName: 'vue-beauty-feed' */ '../components/beauty-feed/index.vue'
  )

const BeautyFeedShow = () =>
  import(
    /* webpackChunkName: 'vue-beauty-feed' */ '../components/beauty-feed/show.vue'
  )

const BeautyFeedFilter = () =>
  import(
    /* webpackChunkName: 'vue-beauty-feed' */ '../components/beauty-feed/filter.vue'
  )

const BeautyPass = () => import('@/components/account/beauty-pass/main.vue')

const RewardsBoutique = () => import('@/components/rewards-boutique/main.vue')

const LazadaMarketplace = () => import('@/components/marketplace/lazada/main.vue')

const LazadaTokenGeneration = () => import('@/components/marketplace/lazada/token-generation.vue')

const AccountIndex = () =>
  import(
    /* webpackChunkName: 'vue-account-index' */ '@/components/account/main.vue'
  )

const AuthPopupPage = () =>
  import(
    /* webpackChunkName: 'vue-account-modal-page' */ '@/components/account/authentication/AuthPopupPage.vue'
  )

const PasswordReset = () =>
  import(
    /* webpackChunkName: 'vue-password-reset' */ '@/components/account/password/reset.vue'
  )

const ShippingMethodInformation = () =>
  import(
    '@/components/checkout-v3/components/sections/shipping-method-section/shipping-method-information.vue'
  )

const SelfCollection = () =>
  import(
    '@/components/checkout-v3/components/sections/shipping-address-section/self-collection-information/main.vue'
  )

const FooterInternational = () =>
  import(
    /* webpackChunkName: 'vue-footer-international' */ '@/components/footer/international/main.vue'
  )

const Faq = () =>
  import(/* webpackChunkName: 'vue-faq' */ '@/components/faq/main.vue')

const CheckoutV3 = () =>
  import(
    /* webpackChunkName: 'vue-checkout' */ '@/components/checkout-v3/components/main.vue'
  )

const CheckoutAsia = () =>
  import(
    /* webpackChunkName: 'vue-checkout-asia' */ '@/components/checkout-asia/components/main.vue'
  )

const GuestSignIn = () =>
  import(
    /* webpackChunkName: 'vue-sign-in' */ '@/components/checkout-asia/components/sign-in.vue'
  )

const RetailAcquisitionsIndex = () =>
  import(
    /* webpackChunkName: 'vue-retail-acquisitions-index' */
    '../components/retail-acquisitions/index/main.vue'
  )

const DigitalCatalogRewards = () =>
  import(
    /* webpackChunkName: 'vue-digital-catalog-rewards' */
    '@/components/digital-catalog/rewards-boutique/main.vue'
  )

const DigitalCatalogShow = () =>
  import(
    /* webpackChunkName: 'vue-digital-catalog-show' */
    '../components/digital-catalog/show/main.vue'
  )

const DigitalCatalogCart = () =>
  import(
    /* webpackChunkName: 'vue-digital-catalog-Cart' */
    '@/components/digital-catalog/cart/main.vue'
  )

  const DigitalCatalogFeedback = () =>
  import(
    /* webpackChunkName: 'vue-digital-catalog-feedback' */
    '@/components/digital-catalog/feedback/main.vue'
  )

const DigitalCatalogLogin = () =>
  import(
    /* webpackChunkName: 'vue-digital-catalog-login' */
    '../components/digital-catalog/login/main.vue'
  )

const BeautyPerk = () =>
  import(
    /* webpackChunkName: 'vue-perks' */
    '@/components/beauty-perks/home/main.vue'
  )

const BeautyPerkDetails = () =>
  import(
    /* webpackChunkName: 'vue-perk-details' */
    '@/components/beauty-perks/perk-details/main.vue'
  )

const LongTailKeywordsShow = () =>
  import(
    /* webpackChunkName: 'vue-long-tail-keywords-show' */
    '@/components/products/long-tail-keywords/show.vue'
  )

const OlapicInstagramGallery = () =>
  import(
    /* webpackChunkName: 'vue-olapic-instagram-gallery' */
    '@/components/olapic/olapic-instagram-gallery.vue'
  )

const OrderStatusDescription = () =>
  import(
    /* webpackChunkName: 'vue-order-status-description' */
    '@/components/account/order/show/order-status-description.vue'
  )

const ConfirmationsIndex = () =>
  import(
    /* webpackChunkName: 'vue-confirmations' */
    '@/components/confirmations/main.vue'
  )

const OrderShow = () =>
  import(
    /* webpackChunkName: 'vue-order-show' */ '@/components/orders/show/main.vue'
  )

const OktaLoginCallback = () =>
  import(
    '@/components/account/authentication/okta/callback.vue'
  )

const PaymentInstruction = () =>
  import(
    /* webpackChunkName: 'vue-order-payment-instruction' */
    '@/components/orders/payment-instruction/main.vue'
  )

export default new Router({
  mode: 'history',
  routes: [
    // Home
    {
      path: '/',
      name: 'home-index',
      children: [
        { path: '/au', redirect: '/' },
        { path: '/id', redirect: '/' },
        { path: '/hk', redirect: '/' },
        { path: '/my', redirect: '/' },
        { path: '/nz', redirect: '/' },
        { path: '/ph', redirect: '/' },
        { path: '/th', redirect: '/' },
        { path: '/sg', redirect: '/' }
      ],
      component: HomeIndex,
      beforeEnter: (to, from, next) => {
        ssrRedirect(to, from, next)
      }
    },

    // Shipping Method Information
    {
      path: '/shipping-method-information',
      name: 'shipping-method-information',
      component: ShippingMethodInformation
    },

    // Self Collection
    {
      path: '/self-collection',
      name: 'self-collection',
      component: SelfCollection
    },

    // Account
    {
      path: '/beauty-pass',
      name: 'beauty-pass',
      component: BeautyPass
    },

    {
      path: '/beautyfeed',
      name: 'beautyfeed',
      component: BeautyFeedIndex
    },

    {
      path: '/beautyfeed/:filter',
      name: 'beautyfeed-index-with-filter',
      component: BeautyFeedIndex,
      props: {
        layout: 'withPageTitle'
      }
    },

    {
      path: '/beautyfeed/article/:slug',
      name: 'beautyfeed-show',
      component: BeautyFeedShow
    },

    {
      path: '/beautyfeed/tag/:slug',
      name: 'beautyfeed-tag',
      component: BeautyFeedFilter
    },

    {
      path: '/beautyfeed/category/:filter',
      name: 'beautyfeed-filter',
      component: BeautyFeedFilter
    },

    {
      path: '/beautyfeed/:contentType/category/:filter',
      name: 'beautyfeed-nested-filter',
      component: BeautyFeedFilter,
      props: {
        nested: true
      }
    },

    // Handle confirmation url
    {
      path: '/confirmations/:actionName/:token',
      name: 'confirmations-index',
      component: ConfirmationsIndex
    },

    // Order status description
    {
      path: '/order-statuses/:name',
      name: 'order-status-description',
      component: OrderStatusDescription
    },

    {
      path: '/rewards-boutique',
      name: 'rewards-boutique',
      component: (vueSupport.currentUser) ? AccountIndex : RewardsBoutique,
      props: {
        view: 'rewards-boutique'
      }
    },

    {
      path: '/wishlist',
      name: 'wishlist',
      component: AccountIndex,
      props: {
        view: 'wishlist'
      },
      meta: {
        returnTo: true
      },
      beforeEnter: (to, from, next) => {
        checkValidUser(to, next)
      }
    },

    {
      path: '/waitlists',
      name: 'waitlist',
      component: AccountIndex,
      props: {
        view: 'waitlist'
      },
      meta: {
        returnTo: true
      },
      beforeEnter: (to, from, next) => {
        checkValidUser(to, next)
      }
    },

    {
      path: '/account/reviews',
      name: 'reviews',
      component: AccountIndex,
      props: {
        view: 'account-reviews'
      },
      meta: {
        returnTo: true
      },
      beforeEnter: (to, from, next) => {
        checkValidUser(to, next)
      }
    },

    {
      path: '/account/point_logs',
      name: 'pointLogs',
      component: AccountIndex,
      props: {
        view: 'point-logs'
      },
      meta: {
        returnTo: true
      },
      beforeEnter: (to, from, next) => {
        checkValidUser(to, next)
      }
    },

    {
      path: '/account/orders/:id',
      name: 'order-details',
      component: AccountIndex,
      props: {
        view: 'OrderDetails'
      },
      meta: {
        returnTo: true
      },
      beforeEnter: (to, from, next) => {
        checkValidUser(to, next)
      }
    },

    {
      path: '/account/retail_orders/:id',
      name: 'retail-order-details',
      component: AccountIndex,
      props: {
        view: 'RetailOrderDetails'
      },
      meta: {
        returnTo: true
      },
      beforeEnter: (to, from, next) => {
        checkValidUser(to, next)
      }
    },

    {
      path: '/account/orders',
      name: 'order-index',
      component: AccountIndex,
      props: {
        view: 'orderIndex'
      },
      meta: {
        returnTo: true
      }
    },

    {
      path: '/account/get-it-again',
      name: 'get-it-again',
      component: AccountIndex,
      props: {
        view: 'GetItAgain'
      },
      meta: {
        returnTo: true
      }
    },

    {
      path: '/account/beauty-profile',
      name: 'beauty-profile',
      component: AccountIndex,
      props: {
        view: 'BeautyProfile'
      },
      meta: {
        returnTo: true
      }
    },

    {
      path: '/account/purchased-items',
      name: 'purchased-items-index',
      component: AccountIndex,
      props: {
        view: 'purchased-items'
      },
      meta: {
        returnTo: true
      }
    },

    {
      path: '/sign_in',
      name: 'auth-popup-signin-page',
      component: AuthPopupPage
    },

    {
      path: '/marketplace-onboarding/lazada',
      name: 'marketplace-onboarding-lazada',
      component: LazadaMarketplace
    },

    {
      path: '/marketplaces/lazada/token_generation',
      name: 'lazada-token-generation',
      component: LazadaTokenGeneration
    },

    {
      path: '/users/password/edit',
      name: 'password-reset',
      component: PasswordReset
    },

    // Footer
    {
      path: '/international',
      name: 'international',
      component: FooterInternational
    },

    // Faq
    {
      path: '/faqs',
      name: 'faq',
      component: Faq
    },
    {
      path: '/faqs/:sectionId',
      name: 'faq-section-show',
      component: Faq,
      props: {
        currentView: 'SectionShow'
      }
    },
    {
      path: '/faqs/:sectionId/:articleId',
      name: 'faq-article-show',
      component: Faq,
      props: {
        currentView: 'ArticleShow'
      }
    },
    {
      path: '/faqs-search/:article*',
      name: 'faq-search-show',
      component: Faq,
      props: {
        currentView: 'Articles'
      },
      meta: {
        entityType: 'search'
      }
    },

    // Booking service
    {
      path: '/beauty-services',
      name: 'booking-service-index',
      component: BookingServiceMain,
      props: {
        currentView: 'ServiceCategoryIndex',
        currentSidebarNav: 'SidebarNavBeautyServices'
      }
    },
    {
      path: '/beauty-services/:id',
      name: 'booking-service-category-show',
      component: BookingServiceMain,
      props: {
        currentView: 'ServiceCategoryShow',
        currentSidebarNav: 'SidebarNavBeautyServices'
      }
    },
    {
      path: '/store-events',
      name: 'discover-event-index',
      component: BookingServiceMain,
      props: {
        currentView: 'DiscoverEventIndex',
        currentSidebarNav: 'SidebarNavStoresAndEvents'
      }
    },
    {
      path: '/store-events/:name',
      name: 'discover-event-show',
      component: BookingServiceMain,
      props: {
        currentView: 'DiscoverEventShow',
        currentSidebarNav: 'SidebarNavStoresAndEvents'
      }
    },
    {
      path: '/store-locations/:id/store-events/:name',
      name: 'discover-event-show',
      component: BookingServiceMain,
      props: {
        currentView: 'DiscoverEventShow',
        currentSidebarNav: 'SidebarNavStoresAndEvents'
      }
    },
    {
      path: '/beauty-classes',
      name: 'beauty-classes-index',
      component: BookingServiceMain,
      props: {
        currentView: 'BeautyClassesIndex',
        currentSidebarNav: 'SidebarNavStoresAndEvents'
      }
    },
    {
      path: '/beauty-classes/:name',
      name: 'beauty-classes-show',
      component: BookingServiceMain,
      props: {
        currentView: 'BeautyClassesShow',
        currentSidebarNav: 'SidebarNavStoresAndEvents'
      }
    },
    {
      path: '/store-locations/:id/beauty-classes/:name',
      name: 'beauty-classes-show',
      component: BookingServiceMain,
      props: {
        currentView: 'BeautyClassesShow',
        currentSidebarNav: 'SidebarNavStoresAndEvents'
      }
    },
    {
      path: '/store-locations',
      name: 'store-location-index',
      component: BookingServiceMain,
      props: {
        currentView: 'FindStoreIndex',
        currentSidebarNav: 'SidebarNavStoresAndEvents'
      }
    },
    {
      path: '/store-locations/:id',
      name: 'store-location-show',
      component: BookingServiceMain,
      props: {
        currentView: 'StoreLocationShow',
        currentSidebarNav: 'SidebarNavStoresAndEvents'
      }
    },
    {
      path: '/stores-and-events',
      name: 'stores-and-events-index',
      component: BookingServiceMain,
      props: {
        currentView: 'StoresAndEventsIndex',
        currentSidebarNav: 'SidebarNavStoresAndEvents'
      }
    },
    {
      path: '/stores-and-events/:id',
      name: 'stores-and-events-show',
      component: BookingServiceMain,
      props: {
        currentView: 'StoresAndEventsShow',
        currentSidebarNav: 'SidebarNavStoresAndEvents'
      }
    },
    // Checkout
    {
      path: '/checkout/:cart_token',
      name: 'checkout',
      component: CheckoutV3,
      props: route => ({
        objectStore: OBJECT_STORES.cart,
        params: route.params
      }),
      meta: {
        entityType: 'checkout'
      }
    },
    // Retry payment page
    {
      path: '/orders/:id/payment_retry',
      name: 'retry-checkout',
      component: CheckoutV3,
      props: route => ({
        objectStore: OBJECT_STORES.order,
        params: route.params
      }),
      meta: {
        entityType: 'checkout'
      }
    },

    {
      path: '/orders/:id/payment_instruction',
      name: 'payment-instruction',
      component: PaymentInstruction,
      props: route => ({
        params: route.params,
        token: route.query.order_token,
        language: route.query?.language // language is optional
      })
    },

    {
      path: '/orders/:id',
      name: 'order-show',
      component: OrderShow,
      props: route => ({
        params: route.params,
        token: route.query.order_token
      })
    },

    {
      path: '/checkout-asia',
      name: 'checkout-asia',
      component: CheckoutAsia
    },

    {
      path: '/guest_sign_in',
      name: 'guest-sign-in',
      component: GuestSignIn
    },
    // Product
    {
      path: '/products/:id',
      name: 'products-show',
      component: ProductsShow,
      meta: {
        returnTo: true
      }
    },

    {
      path: '/products/:id/v/:variantId',
      name: 'products-show-variant',
      component: ProductsShow,
      meta: {
        returnTo: true
      }
    },

    {
      path: '/products/:id/reviews',
      name: 'products-show-reviews',
      component: ProductsReviews,
      meta: {
        returnTo: true
      }
    },

    // Brand
    {
      path: '/brands',
      name: 'brands-index',
      component: BrandsIndex
    },

    {
      path: '/brands/:id/:category*',
      name: 'brands-show',
      component: ProductsIndex,
      meta: {
        returnTo: true,
        entityType: 'brands'
      },
      beforeEnter: (to, from, next) => {
        ssrRedirect(to, from, next)
      }
    },

    // Category
    {
      path: '/categories/:id+',
      name: 'categories-show',
      component: ProductsIndex,
      meta: {
        returnTo: true,
        entityType: 'categories'
      },
      beforeEnter: (to, from, next) => {
        ssrRedirect(to, from, next)
      }
    },

    // Offers
    {
      path: '/offers/:id/:category*',
      name: 'offers-show',
      component: ProductsIndex,
      meta: {
        returnTo: true,
        entityType: 'offers'
      }
    },

    // Virtual Bundles
    {
      path: '/bundles/:id',
      name: 'virtual-bundles-show',
      component: VirtualBundlesShow
    },

    // Landing Page
    {
      path: '/pages/:id/:category*',
      name: 'landing-pages-show',
      component: LandingPagesShow
    },

    // RetailAcquisition
    {
      path: '/users/sign-up-retail',
      name: 'retail-acquisitions',
      component: RetailAcquisitionsIndex
    },

    // Okta Login Callback
    {
      path: '/login/callback',
      name: 'okta-login-callback',
      component: OktaLoginCallback,
      meta: {
        entityType: 'okta'
      }
    },

    // DigitalCatalog
    {
      path: '/digital-catalog/:storeIdentifier',
      name: 'digital-catalog-homepage',
      component: DigitalCatalogShow
    },

    // DigitalCatalogRewards
    {
      path: '/digital-catalog/:storeIdentifier/rewards-boutique',
      name: 'digital-catalog-rewards',
      component: DigitalCatalogRewards
    },

    // DigitalCatalogCart
    {
      path: '/digital-catalog/:storeIdentifier/cart',
      name: 'digital-catalog-cart',
      component: DigitalCatalogCart
    },

    {
      path: '/digital-catalog/:storeIdentifier/feedback',
      name: 'digital-catalog-feedback',
      component: DigitalCatalogFeedback
    },

    {
      path: '/digital-catalog/:storeIdentifier/login',
      name: 'digital-catalog-login',
      component: DigitalCatalogLogin
    },

    // Perks
    {
      path: '/beauty-perks',
      name: 'beauty-perks',
      component: BeautyPerk
    },

    {
      path: '/beauty-perks/promotions/:id',
      name: 'beauty-perks-promotion-details',
      component: BeautyPerkDetails,
      meta: {
        perkType: 'promotion'
      }
    },

    {
      path: '/beauty-perks/offers/:id',
      name: 'beauty-perks-offer-details',
      component: BeautyPerkDetails,
      meta: {
        perkType: 'offer'
      }
    },

    {
      path: '/sale/:category*',
      name: 'landing-pages-sale',
      component: ProductsIndex,
      meta: {
        returnTo: true,
        entityId: 'sale', // There should probably be a better way to do this
        entityType: 'landing-pages'
      }
    },

    // Search
    {
      path: '/search/:category*',
      name: 'search-show',
      component: ProductsIndex,
      meta: {
        returnTo: true,
        entityType: 'search'
      }
    },

    // Long tail keywords
    {
      path: '/buy/:longTailKeyword',
      name: 'long-tail-keywords-show',
      component: LongTailKeywordsShow
    },

    // Olapic Instagram gallery
    {
      path: '/beauty-gallery',
      name: 'olapic-instagram-gallery',
      component: OlapicInstagramGallery
    },

    // Product Group
    {
      path: '/:id/:category*',
      name: 'product-groups-show',
      component: ProductsIndex,
      meta: {
        returnTo: true,
        entityType: 'product-groups'
      }
    }
  ],

  scrollBehavior (to, from, savedPosition) {
    if (from.name === to.name) {
      return
    }

    const position = savedPosition || { x: 0, y: 0 }
    const scrollEvent = to.meta.customScrollEvent || 'triggerScroll'

    return new Promise(resolve => {
      // Wait until transition has finished and emitted the "triggerScroll" event
      // Prevent page from jumping while old page is still showing
      this.app.$root.$once(scrollEvent, () => {
        resolve(position)
      })
    })
  }
})
