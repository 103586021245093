import Reward from '@/modules/api/reward'

const rewardApi = new Reward()

const state = {
  rewards: [],
  retailRewards: [],
  retailStoreName: ''
}

const mutations = {
  setRewards (state, rewards) {
    state.rewards = rewards
  },
  setRetailRewards (state, retailRewards) {
    state.retailRewards = retailRewards
  },
  setRetailStoreName (state, retailStoreName) {
    state.retailStoreName = retailStoreName
  }
}

const actions = {
  async fetchRewards ({ commit }) {
    try {
      const { data, included } = await rewardApi.fetch()
      const rewards = mapRewards(data, included)

      commit('setRewards', rewards)
    } catch { }
  },

  async fetchRetailRewards ({ commit },
    {
      storeCode,
      page,
      pageSize,
      rewardIds
    } = {}) {
    try {
      const { data, included } = await rewardApi.fetchRetailRewards(
        storeCode, { page: page, pageSize: pageSize, rewardIds: rewardIds }
      )
      const retailRewards = mapRewards(data, included)
      const retailStore = included.find(i => i.type === 'sephora-retail-stores')
      commit('setRetailRewards', retailRewards)
      commit('setRetailStoreName', retailStore.attributes['name'])
    } catch { }
  }
}

function mapRewards (data, included) {
  const rewards = data.map(reward => {
    const labels = []

    if (reward.relationships['tier-exclusive-labels']) {
      reward.relationships['tier-exclusive-labels']['data'].forEach(label => {
        const tierLabel = included.find(i => i.id === label['id'] && i.type === 'tier-exclusive-labels')
        labels.push({ text: tierLabel.attributes.text, hexCode: tierLabel.attributes['hex-code'] })
      })
    }

    const variantId = reward.relationships.variant.data.id
    const variant = included.find(i => i.id === variantId && i.type === 'variants')
    const productId = variant.relationships.product.data ? { productId: variant.relationships.product.data['id'] } : {}
    const sampleId = reward.attributes['checkoutable-id'] ? { sampleId: reward.attributes['checkoutable-id'] } : {}
    return {
      id: reward.id,
      points: reward.attributes['point-value'],
      originalPoints: reward.attributes['original-point-value'],
      discountLabel: reward.attributes['discount-label'],
      brandName: variant.attributes['brand-name'],
      productName: variant.attributes['product-name'],
      tierExclusiveLabels: labels,
      icon: variant.attributes['icon-url'],
      editorsNote: reward.attributes['editors-note'],
      isRedeemable: reward.attributes['is-available-for-redemption'],
      isFullyRedeemed: reward.attributes['is-fully-redeemed'],
      variantId: variant.id,
      variantSlugUrl: variant.attributes['slug-url'],
      productSlugUrl: reward.attributes['product-slug-url'],
      tnc: reward.attributes['tnc'],
      redemptionLimit: reward.attributes['redemption-limit'],
      redemptionLeft: reward.attributes['redemptions-left'],
      tier: reward.attributes['tier'],
      ...productId,
      ...sampleId
    }
  })
  return rewards
}

const getters = {
  lowRewards: (state) => {
    return state.rewards.filter(reward => reward.points < 500)
  },

  highRewards: (state) => {
    return state.rewards.filter(reward => reward.points >= 500)
  },

  lowRetailRewards: (state) => {
    return state.retailRewards.filter(reward => reward.points < 500)
  },

  highRetailRewards: (state) => {
    return state.retailRewards.filter(reward => reward.points >= 500)
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
