import Vue from 'vue'
import Vuex from 'vuex'

import authentication from './modules/authentication'
import cart from './modules/cart'
import user from './modules/user'
import layout from './modules/layout'
import brands from './modules/brands'
import category from './modules/category'
import reward from './modules/reward'
import search from './modules/search'
import wishlist from './modules/wishlist'
import waitlist from './modules/waitlist'
import product from './modules/product'
import retailCart from './modules/retail-cart'
import globalConfig from './modules/global-config'
import digitalCatalog from './modules/digital-catalog'
import beautyPerks from './modules/beauty-perks'
import overlay from './modules/overlay'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    authentication,
    cart,
    user,
    layout,
    brands,
    category,
    reward,
    search,
    wishlist,
    waitlist,
    product,
    retailCart,
    globalConfig,
    digitalCatalog,
    beautyPerks,
    overlay
  }
})
