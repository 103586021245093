import Jsona, { SwitchCaseModelMapper, SwitchCaseJsonMapper } from 'jsona'
import camelCase from 'lodash/camelCase'
import isNil from 'lodash/isNil'

// Added this due to jsona library does not handle camelizing nested objects
// https://stackoverflow.com/a/50620653
export const camelizeKeys = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map(v => camelizeKeys(v))
  }
  if (!isNil(obj) && obj.constructor === Object) {
    return Object.keys(obj).reduce((result, key) => ({
      ...result,
      [camelCase(key)]: camelizeKeys(obj[key])
    }), {})
  }
  return obj
}

const dataFormatter = new Jsona({
  modelPropertiesMapper: new SwitchCaseModelMapper(),
  jsonPropertiesMapper: new SwitchCaseJsonMapper()
})

// Converts response like:
// {
//   data: {
//     id: "1",
//     type: "global-config",
//     attributes: {
//       name: 'John',
//       long-name: 'Jonathan'
//     }
//   }
// }

// To:
// {
//   type: "globalConfig",
//   name: 'John',
//   longName: 'Jonathan'
// }
export const format = (data) => {
  const deserializedData = dataFormatter.deserialize(data)
  return camelizeKeys(deserializedData)
}

export default dataFormatter
