<template lang='pug'>
  component(:is='layout')
    template(slot='page-content')
      slot(name='page-content')
</template>

<script>
import { pushDataLayerEvent, resetDataLayer } from '@/modules/services/gtm'
import { deeplinkForRoute } from '@/modules/services/deeplink-url'
import { fullUrl } from '@/modules/services/utilities'

export default {
  components: {
    default: () => import(/* webpackChunkName: 'vue-default-layout' */ '@/layouts/default'),
    blank: () => import(/* webpackChunkName: 'vue-blank-layout' */ '@/layouts/blank')
  },

  computed: {
    layout () {
      return vueSupport.layout || 'default'
    }
  },

  mounted () {
    this.$router.afterEach((to, from) => {
      this.$store.commit('setSeoDeeplinkUrl', deeplinkForRoute(to))

      // Global-e tracking:
      // - skip order confirmation dataLayer push as it's handled via Global-e callback
      if (to.name === 'checkout-asia') {
        if (to.path !== 'checkout-asia#Global-e_International_Checkout') { return }
      }

      // non-search queries should not send DynamicPageView for catalogue pages
      // ensure that navigation from non-spa or first load to send DynamicPageView
      if (to.path !== from.path || from.name === null) {
        pushDataLayerEvent('DynamicPageView', { pageUrl: fullUrl(to.fullPath) })
      }
      resetDataLayer()
    })
  }
}
</script>
