import ApiBase from './api-base'

class LocationSearches extends ApiBase {
  constructor () {
    super()

    this.url = '/v2.5/location_searches'
  }

  async recent () {
    const { data } = await this.client.get(`${this.url}/recent`)
    return data
  }
}

export default LocationSearches
