import { isSsrActivated, isListingPages, isHomepage } from '@/mixins/ssrUtil'

function ssrRedirect (to, from, next) {
  if (isSsrActivated() && (isHomepage(to, from) || isListingPages(to, from))) {
    window.location.href = to.fullPath
  } else {
    next()
  }
}

function isSsrListingPages (to, from) {
  return isSsrActivated() && isListingPages(to, from)
}

export {
  ssrRedirect,
  isSsrListingPages
}
