const GRAPHQL_SORT_VALUES = {
  'relevance': 'RELEVANCE',
  'recommended': 'RECOMMENDED',
  'sales': 'BESTSELLING',
  'published_at': 'MOST_RECENT',
  'rating': 'TOP_RATED',
  'price': 'PRICE_LOW_TO_HIGH',
  '-price': 'PRICE_HIGH_TO_LOW'
}

function mappingGraphQLAttributes (response) {
  const data = []
  const included = []

  const products = response.products.edges
  const productsLength = products.length

  for (let i = 0; i < productsLength; i++) {
    const product = products[i].node
    // prepare data
    data.push(mapProductAttributes(product))

    // prepare included
    included.push(mapBrandAttributes(product.brand))

    const variantsLength = product.variants.length
    for (let j = 0; j < variantsLength; j++) {
      included.push(mapVariantAttributes(product, product.variants[j], product.brand))
    }

    if (product.featuredAd) { included.push(mapAdAttributes(product.featuredAd)) }
  }

  return { data, included }
}

function mapProductAttributes (product) {
  const featuredVariant = featuredVariantFor(product)

  return {
    'id': product.id,
    'attributes': {
      'closeup-image-urls': featuredVariant.closeUpImages.map(image => image.url),
      'default-image-urls': featuredVariant.defaultImages.map(image => image.url),
      'featured-variant-id': parseInt(featuredVariant.id),
      'icon-image-urls': featuredVariant.iconImages.map(image => image.url),
      'image-urls': [],
      'name': product.name,
      'new-arrival': product.newArrival,
      'option-type-categories': product.optionTypeCategories,
      'original-price': featuredVariant.price.originalPrice,
      'price': featuredVariant.price.price,
      'rating': product.rating,
      'reviews-count': product.reviewsCount,
      'sale-text': featuredVariant.saleText,
      'slug-url': product.slug,
      'sold-out': featuredVariant.inventory <= 0,
      'under-sale': featuredVariant.price.onSale,
      'variants-count': product.variants.length,
      'web-url': product.webUrl
    },
    'relationships': {
      'brand': { data: { id: product.brand.id, type: 'brands' } },
      'categories': { data: [] },
      'featured-ad': { data: product.featuredAd ? { id: product.featuredAd.id, type: 'ads' } : null },
      'featured-variant': { data: { type: 'variants', id: product.featuredVariant.id } },
      'option-types': { data: [] },
      'product-articles': { data: [] },
      'variants': product.variants.map(variant => ({ id: variant.id, type: 'variants' }))
    },
    'type': 'products'
  }
}

function mapVariantAttributes (product, variant, brand) {
  return {
    id: variant.id,
    attributes: {
      'brand-name': brand.name,
      'brand-slug-url': brand.slug,
      'closeup-image-urls': variant.closeUpImages.map(image => image.url),
      'default-image-urls': variant.defaultImages.map(image => image.url),
      'icon-image-urls': variant.iconImages.map(image => image.url),
      'icon-url': variant.iconUrl,
      'inventory': variant.inventory,
      'name': variant.name,
      'new-arrival': product.newArrival,
      'original-price': variant.price.originalPrice,
      'price': variant.price.price,
      'product-name': product.name,
      'reviews-count': product.reviewsCount,
      'sale-text': variant.saleText,
      'slug-url': variant.slug,
      'sold-out': variant.inventory <= 0,
      'under-sale': variant.price.onSale
    },
    relationships: {
      'ads': { data: [] },
      'option-values': { data: [] },
      'product': { data: { id: product.id, type: 'products' } }
    },
    type: 'variants'
  }
}

function mapBrandAttributes (brand) {
  return {
    id: brand.id,
    attributes: {
      'name': brand.name,
      'slug-url': brand.slug
    },
    type: 'brands'
  }
}

function mapAdAttributes (ad) {
  return {
    id: ad.id,
    attributes: {
      'short-title': ad.shortTitle,
      'variant-ids': ad.variants.map(variant => parseInt(variant.id))
    },
    type: 'ads'
  }
}

function featuredVariantFor (product) {
  return product.variants.find(variant => variant.id === product.featuredVariant.id)
}

export {
  GRAPHQL_SORT_VALUES,
  mappingGraphQLAttributes
}
