import accounting from 'accounting'
import locale from '../i18n/locales.js'

function centsToDollars (cents) {
  return parseFloat(cents) / 100
}

function dollarsToCents (dollars) {
  return parseFloat(dollars) * 100
}

function updateOptions (options, country, symbol) {
  options = Object.assign(options, {
    symbol: symbol,
    ...(locale[country].currency.format
      ? { format: locale[country].currency.format }
      : {}
    ),
    ...(locale[country].currency.decimal
      ? { decimal: locale[country].currency.decimal }
      : {}
    ),
    ...(locale[country].currency.thousand
      ? { thousand: locale[country].currency.thousand }
      : {}
    ),
    ...(locale[country].currency.precision !== undefined
      ? { precision: locale[country].currency.precision }
      : {}
    )
  })

  return options
}

function humanizePrice (price, options = {}, isCents = true) {
  let dollars = price

  if (isCents) {
    dollars = centsToDollars(price)
  }

  const country = options.country || I18n.siteCountryLs
  const symbol = options['showOriginalCurrency'] && vueSupport.crossBorderCountry ? locale[country].currency.originalSymbol : locale[country].currency.symbol

  options = updateOptions(options, country, symbol)

  return accounting.formatMoney(dollars, options)
}

export {
  humanizePrice,
  centsToDollars,
  dollarsToCents
}
