import { refreshTokenPath } from '@/modules/config/authentication'

export function removeDuplicatedApiInterceptor (axiosInstance) {
  axiosInstance.interceptors.request.use(function (config) {
    if (config.url.includes(refreshTokenPath)) {
      config.baseURL = '/'
      config.basePath = '/'
      config.url = `/api/${refreshTokenPath}`
    } else {
      config.url = config.url.replace(/^\/api\//i, '')
    }

    return config
  })
}
