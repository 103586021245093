import ApiBase from './api-base'
import ServiceAgreementAdapter from './adapters/serviceAgreement'
import { v2m5mHeaders } from '@/modules/config/api'

class ServiceAgreement extends ApiBase {
  constructor () {
    super()
    this.adapters = {
      serviceAgreement: new ServiceAgreementAdapter()
    }
    this.url = '/v2.5/auth/service_agreements'
  }

  async fetchServiceAgreements () {
    const url = this.url
    const { data } = await this.client.get(url, {
      headers: v2m5mHeaders
    })

    return { data: this.adapters.serviceAgreement.adaptData(data.data) }
  }
}

export default ServiceAgreement
