export default class SearchCategoriesTransformer {
  constructor (categories, included) {
    this.categories = categories
    this.included = included
  }

  get transformedData () {
    return this.categories.map((category) => {
      return this.included.find((included) => {
        return included.id.toString() === category.id.toString() && included.type === 'categories'
      })
    })
  }
}
