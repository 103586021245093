function filterByType (data, type, condition = null) {
  if (typeof condition === 'function') {
    return data.filter(item => item.type === type && condition(item))
  }
  return data.filter(item => item.type === type)
}

function findByType (data, type, condition = null) {
  if (typeof condition === 'function') {
    return data.find(item => item.type === type && condition(item))
  }
  return data.find(item => item.type === type)
}

export {
  filterByType,
  findByType
}
