import ApiBase from './api-base'
import { getCookie } from '@/modules/services/cookies'

class BeautyPerks extends ApiBase {
  headers = {
    'X-Site-Country': I18n.siteCountryLs.toUpperCase(),
    'X-Platform': getCookie('platform') || 'mobile_web',
    'X-App-Platform': getCookie('app_platform') || 'web_mobile',
    'X-App-Version': getCookie('app_version') || '1.0.0',
    'Content-Type': 'application/json'
  }

  async fetchPromotions ({
    pageSize = 50,
    pageNumber = 1
  } = {}) {
    const { data } = await this.client.get('/v2.5/beauty_perks/promotions', {
      params: {
        'page[size]': pageSize,
        'page[number]': pageNumber
      },
      headers: this.headers
    })

    return data
  }

  async fetchBeautyPerksDiscountCoupons ({
    pageSize = 30,
    pageNumber = 1
  } = {}) {
    const { data } = await this.client.get('/v2.5/beauty_perks/discount_coupons', {
      params: {
        'page[size]': pageSize,
        'page[number]': pageNumber
      },
      headers: this.headers
    })

    return data
  }

  async fetchBeautyPass () {
    const { data } = await this.client.get('/v2.5/beauty_perks/insider_gifts', {
      headers: this.headers
    })

    return data
  }
}

export default BeautyPerks
